import { Route, RouteConfig } from 'vue-router/types/router';
import { CollectionService } from '@/services/CollectionService';
import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';

const collectionService = CollectionService.getInstance();

const config: Array<RouteConfig> = [
  {
    path: 'collections',
    component: () => import(/* webpackChunkName: "Collections" */ '@/views/Dashboard/Collections/Collections.vue'),
    meta: {
      requiresAuth: true,
      breadcrumbs(): RouterBreadcrumb[] {
        return [
          {
            text: 'Collections',
            to: { name: 'collections-list' },
          },
        ];
      },
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        name: 'collections-list',
        component: () => import(/* webpackChunkName: "Collections" */ '@/views/Dashboard/Collections/CollectionsList.vue'),
        meta: {
          title: 'List Collections',
          subtitle: 'Browse through all the accessible collection records that exist within our system.',
        },
      },
      {
        path: 'create',
        name: 'collections-create',
        component: () => import(/* webpackChunkName: "Collections" */ '@/views/Dashboard/Collections/CollectionsCreate.vue'),
        props: (route: Route) => {
          if (route.query.blacklistParentCollectionId) {
            const blacklistParentCollection = collectionService.find(route.query.blacklistParentCollectionId as string);
            return {
              blacklistParentCollection,
            };
          }
        },
        beforeEnter(to, _from, next) {
          // If we're not using the create form for blacklist collections, bounce
          // back to the collections list
          if (!to.query.blacklistParentCollectionId) {
            next({ name: 'collections-list' });
          } else {
            const blacklistParentCollection = collectionService.find(to.query.blacklistParentCollectionId as string);
            if (blacklistParentCollection) {
              to.meta.title = `Create Blacklist Collection for ${blacklistParentCollection.name}`;
              to.meta.subtitle = `Make a new blacklist to exclude certain products for select sites for Collection ${blacklistParentCollection.name}`;
              next();
            } else {
              const error = new Error(`Unable to find blacklist collection, "${to.query.blacklistParentCollectionId}"`);
              next(error);
            }
          }
        },
        meta: {
          requiresAuth: true,
          title: 'Create Collection',
          subtitle: 'Fill out the required form below to create a new Collection.',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'Create',
                to: { name: 'collections-create' },
              },
            ];
          },
        },
      },
      {
        path: ':id',
        name: 'collections-view',
        component: () => import(/* webpackChunkName: "Collections" */ '@/views/Dashboard/Collections/CollectionsView/CollectionsView.vue'),
        props: (route: Route) => ({ id: route.params.id }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'View Collection',
          subtitle: 'Review all the details related to this collection.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [
              {
                text: this.$route.params.id,
                to: { name: 'collections-view', params: { id: this.$route.params.id } },
              },
            ];
          },
        },
      },
      {
        path: ':id/edit',
        name: 'collections-edit',
        component: () => import(/* webpackChunkName: "Collections" */ '@/views/Dashboard/Collections/CollectionsEdit.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'Edit Collection',
          subtitle: 'Modify all the details and data related to this collection.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [
              {
                text: this.$route.params.id,
                to: { name: 'collections-view', params: { id: this.$route.params.id } },
              },
              {
                text: 'Edit',
                to: { name: 'collections-edit', params: { id: this.$route.params.id } },
              },
            ];
          },
        },
      },
    ],
  },
];

export default config;
