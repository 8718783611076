import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import Utility from '@/tools/Utility';
import { RouteConfig } from 'vue-router/types/router';
import store from '@/store';

const config: Array<RouteConfig> = [
  {
    path: 'docs',
    component: () => import(/* webpackChunkName: "Docs" */ '@/views/Dashboard/Docs/Docs.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ':doc',
        name: 'docs-md',
        component: () => import(/* webpackChunkName: "Docs" */ '@/views/Dashboard/Docs/Markdown.vue'),
        beforeEnter: (to, from, next) => {
          if (to.query.popped) {
            // Set layout state here since beforeEnter guard is run AFTER global beforeEach guard
            // and route meta changes will occur after
            store.set('layout/name', 'full-screen');
          }
          next();
        },
        props: (route) => {
          const p = { popped: '', doc: route.params.doc };
          if (route.query.popped) p.popped = 'yes';
          return p;
        },
        meta: {
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: Utility.titleCase(this.$route.params.doc),
            }];
          },
        },
      },
    ],
  },
];

export default config;
