import { ModelService } from './ModelService';
import { Order, OrderItem } from '@/models/internal';
import { Item } from '@/lib/types';
import { IApiService, ICreateArguments, IDestroyArguments, IFindArguments, IFindOneArguments, IUpdateArguments } from '@/lib/interfaces';
import { ResponseType } from 'axios';

/**
 * Service class for interacting with the Order model
 */
export class OrderService extends ModelService<typeof Order> {
  /**
   * Cached instance of the service
   */
  private static instance: OrderService | null = null;

  protected model = Order;

  protected path = '/draft_orders';

  /**
   * Constructor
   */
  private constructor() {
    super();
  }

  /**
   * Get an instance of the CustomerService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new OrderService();
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Process/parse data from the server before inserting into Model.
   * @param data Order data from server
   */
  /**
   * Process/parse data from the server before inserting into Model.
   * @param data Order data from server
   */
  public static mapData(data: any) {
    return {
      id: data.id,
      status: data.status,
      created_at: data.created_at,
      updated_at: data.updated_at,
      submitters_email: data.submitters_email,
      order_items: data.order_items,
      order_form_endpoint_id: data.order_form_endpoint_id,
      previous_form_states: data.previous_form_states,
      approvals: data.last_form.approvals,
      ship_address: data.last_form.ship_address,
      bill_address: data.last_form.bill_address,
      order_detail: data.last_form.order_detail,
      comments: data.comments ?? [],
      section_counts: data.section_counts,
      fulfillment_ids: data.bridge_order_ids,
    };
  }

  public getActive(): Item<Order> {
    return this.model.getActive();
  }

  public get api(): IApiService {
    return {
      /**
       * Create a Order on the server via POST request
       */
      create: async (args: ICreateArguments) => {
        return this.apiService.post(this.path, args);
      },

      /**
       * Find one Order on the server via GET request
       */
      findOne: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Find a list of Orders on the server via GET request
       */
      find: async (args: IFindArguments) => {
        const { data } = await this.apiService.get(this.path, args);
        return data;
      },

      /**
       * Update an existing Order on the server via PUT request
       */
      update: async (args: IUpdateArguments) => {
        return this.apiService.patch(`${this.path}/${args.id}`, args);
      },

      /**
       * Delete an existing Order on the server via DELETE request
       */
      destroy: async (args: IDestroyArguments) => {
        return this.apiService.delete(`${this.path}/${args.id}`, args);
      },

      /**
       * Download an Order's PDF.
       * @param args Arguments
       * @returns
       */
      download: async (args: IDownloadOrderArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}/download`, args, {
          responseType: args.response_type ?? 'blob',
        });
        return data;
      },
    };
  }

  /**
   * Create a map of OrderItems to form-sections (i.e. Collection code, etc)
   * @param orderItems Array of OrderItems
   */
  public mapOrderItems(orderItems: OrderItem[]): Record<string, OrderItem[]> {
    return orderItems.reduce((acc, orderItem) => {
      const { form_section } = orderItem;

      if (!form_section) {
        return acc;
      }

      if (typeof acc[form_section] === 'undefined') {
        acc[form_section] = [orderItem];
      } else {
        acc[form_section].push(orderItem);
      }

      return acc;
    }, {} as Record<string, OrderItem[]>);
  }
}

/**
 * Download Order PDF arguments
 */
interface IDownloadOrderArguments extends IFindOneArguments {
  /**
   * Response type header
   */
  response_type?: ResponseType;
}
