import { ModelService } from './ModelService';
import { Fulfillment } from '@/models/internal';

/**
 * FulfillmentService provides functionality for interacting
 * with the Fulfillment model.
 *
 * NOTE: Fulfillment is a client-side entity - there's no API
 * support for it. Therefore we set the `path` and `api` properties
 * to null as they're only used to make requests to the server.
 */
export class FulfillmentService extends ModelService<typeof Fulfillment> {
  private static instance: FulfillmentService | null = null;

  public api = {
    find: async (args: any): Promise<any> => {
      const path = this.path.replace(':order_id', args.order_id.toString());
      const { data } = await this.apiService.get(path, args);
      return data;
    },
    findOne: async (args: any): Promise<any> => {
      const path = this.path.replace(':order_id', args.order_id.toString());
      const { data } = await this.apiService.get(`${path}/${args.id}`, args);
      return data;
    },
    create: async (): Promise<any> => {
      throw Error('Method not implemented');
    },
    destroy: async (): Promise<any> => {
      throw Error('Method not implemented');
    },
    update: async (): Promise<any> => {
      throw Error('Method not implemented');
    },
  };

  protected model = Fulfillment;

  protected path = 'draft_orders/:order_id/fulfillments';

  private constructor() {
    super();
  }

  /**
   * Get an instance of the service.
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new FulfillmentService();
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Process/parse data from the server before inserting into Model.
   * @param data Fulfillment data from server
   */
  public static mapData(data: any) {
    // Reduce line length by unpacking these properties
    const { destination_order_id, order } = data;

    try {
      // Pull out the Order ID from the `destination_order_id` from server
      const [orderId] = String(destination_order_id)
        .split(/_/)
        .map((s) => s.toLocaleLowerCase());

      return {
        id: data.order_id, // Order Marshal's `Order` model ID
        order_id: parseInt(orderId, 10), // Parsed `DraftOrder` ID
        status: data.status,
        fulfillment_detail: order.order_detail,
        fulfillment_reference: destination_order_id,
        created_at: data.created_at,
        updated_at: data.updated_at,
      };
    } catch (error) {
      throw Error('Unable to parse Order ID from Fulfillment: ' + error.message);
    }
  }
}
