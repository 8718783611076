import { ContextBarAction } from '@/components/ContextBar/types/ContextBarAction.type';
import store from '@/store';
import differenceBy from 'lodash/differenceBy';

/**
 * ContextBarManager
 *
 * Used to update the "globalish" component, `ContextBar`
 * with actions, and other UI/UX-related functionalities.
 */
export class ContextBarManager {
  /**
   * Add action(s) to the context-bar.
   * @param action Action(s) to add to context bar
   */
  public static setActions(...actions: ContextBarAction[]): void {
    actions.forEach(this.validateAction);

    this.clearActions();
    this.addActions(...actions);
  }

  /**
   * Add action(s) to the context-bar.
   * @param action Action(s) to add to context bar
   */
  public static addActions(...actions: ContextBarAction[]): void {
    actions.forEach(this.validateAction);

    const currentActions = store.get('ui/contextBar@actions');
    const newActions = [...currentActions, ...actions];

    store.set('ui/contextBar@actions', newActions);
  }

  /**
   * Remove action(s) from the context-bar.
   * @param action Action(s) to remove from context bar
   */
  public static removeActions(...actions: Pick<ContextBarAction, 'label'>[]): void {
    const currentActions = store.get('ui/contextBar@actions') as ContextBarAction[];
    if (!currentActions || !currentActions.length) return;

    const newActions = differenceBy(currentActions, actions, 'label');

    store.set('ui/contextBar@actions', newActions);
  }

  /**
   * Clear all the actions from the ContextBar, returning those that have
   * been removed.
   * @returns Actions cleared from ContextBar
   */
  public static clearActions(): void {
    store.set('ui/contextBar@actions', []);
  }

  /**
   * Validate an action and return the result.
   * @param action Action to validate
   * @returns Action is valid
   */
  public static validateAction(action: ContextBarAction): boolean {
    if (!action) throw Error('Invalid action provided to context-bar');
    if (!action.callback && !action.to) throw Error('Need to provide one of the following properties: [callback, to]');
    if (action.callback && action.to) throw Error('Cannot provide both of the following properties - only one: [callback, to]');

    return true;
  }
}

export default ContextBarManager;
