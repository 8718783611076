
















import Vue from 'vue';
import { Utility } from '@/tools/Utility';

export default Vue.extend({
  name: 'FullScreenLayout',
  data: () => ({
    applicationName: '',
    year: new Date().getFullYear(),
  }),
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const config = await Utility.fetchConfig();
      this.applicationName = config.portal.name;
    },
  },
});
