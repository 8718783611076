










































import { Vue, Component, Prop } from 'vue-property-decorator';
import { NavigationItem } from './NavigationItem.vue';
import { NavigationLink } from './links';

@Component({
  name: 'NavigationItems',
  components: {
    NavigationItem,
  },
})
export class NavigationItems extends Vue {
  @Prop({ required: true })
  protected readonly items!: NavigationLink[];

  @Prop({ required: true })
  protected readonly hover!: boolean;

  protected getGroup(item: NavigationLink) {
    if (!item.group) return undefined;
    return item.group;
  }
}

export default NavigationItems;
