import { ApiService } from '.';
import { IRequestArguments } from '@/lib/interfaces';

export class ReportService {
  /**
   * Cached instance of the service
   */
  private static instance: ReportService | null = null;

  protected path = '/reports';

  /**
   * Service for making requests to the API
   */
  private readonly apiService: ApiService = ApiService.getInstance();

  /**
   * Constructor
   */
  private constructor() {
    if (ReportService.instance) {
      throw Error('Use `getInstance` instead of constructor');
    }
  }

  /**
   * Get an instance of the UserService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new ReportService();
      return this.instance;
    }
    return this.instance;
  }

  public get api() {
    return {
      rushSummary: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/rush_summary`, args);
        return data;
      },
      eocPpeSupply: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/eoc_ppe_supply`, args);
        return data;
      },
      ppeSupply: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/ppe_supply`, args);
        return data;
      },
      distChannelPpeSupply: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/dist_channel_ppe_supply`, args);
        return data;
      },
      distChannelPpeOrderSummary: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/dist_channel_ppe_order_summary`, args);
        return data;
      },
      distChannelRushSummary: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/dist_channel_rush_summary`, args);
        return data;
      },
      communitySitesPpeShipped: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/community_sites_ppe_shipped`, args);
        return data;
      },
      communitySitesOrderPriority: async (args: IRequestArguments) => {
        const { data: { data } } = await this.apiService.get(`${this.path}/community_sites_order_priority`, args);
        return data;
      },
      downloadPdf: async (args: IRequestArguments) => {
        const { data } = await this.apiService.post(`${this.path}/download`, args, {
          responseType: 'blob',
        });
        return data;
      },
      projectedInventory: async (args: any): Promise<ProjectedInventoryResponse> => {
        const { data } = await this.apiService.get('/reports/projected-inventory', args);
        return data;
      },
    };
  }
}

export type ProjectedInventoryResponse = {
  status: string;
  data: {
    /** Page number */
    page: number;
    /** Results per page */
    perPage: number;
    /** Results */
    results: {
      /**
       * All the results for given a SKU in provided date-range, keyed by
       * SKU identifier.
       */
      [uid: string]: ProjectedInventoryDatum[],
    };
    /** Search string used to filter results */
    search: string;
    /** Total number of records in the database */
    total: number;
  }
};

export type ProjectedInventoryDatum = {
  /** Estimated arrival date for PO. */
  arrivalDate: string;
  /** Quantity to be recieved on arrival date. */
  arrivalQuantity: 0;
  /**
   * Used to figure out if we need to subtract the UR quantity from
   * the next group of orders or not. Not really applicable to the
   * final output.
   */
  nextTrans?: string;
  /**
   * Number of units being called for by the orders if `NextTrans`
   * is "orders".
   */
  pendingRelease: number;
  /**
   * Total stock quantity available at time of PO arrival. Equals
   * the `runningTotal` minus the `pendingRelease`.
   */
  quantity: number;
  /** Running total. */
  runningTotal: number;
  /** SKU Details. */
  sku: {
    id: string;
    color: string;
    description: string;
  };
  /** Transaction type */
  transactionType: 'CIS' | 'PO';
};

