import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "Error" */ '@/views/Error.vue'),
    meta: {
      layout: 'full-screen',
    },
    props: (route) => {
      const { code, reason, message, hideHomeButton } = route.query;
      return {
        code: code ?? 404,
        reason: reason ?? 'Page Not Found',
        message: message ?? 'Sorry, we\'re unable to find the page you\'re looking for!',
        hideHomeButton,
      };
    },
  },
  {
    path: '*',
    name: 'unsupported-browser',
    component: () => import(/* webpackChunkName: "UnsupportedBrowser" */ '@/views/UnsupportedBrowser.vue'),
    meta: {
      layout: 'full-screen',
    },
  },
];

export default config;
