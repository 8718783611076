import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import vuexPersist from '@/plugins/vuex-persist';
import pathify from '@/plugins/vuex-pathify';
import * as modules from './modules';
import { VuexOrmPlugin } from '@/plugins/vuex-orm';
import { ORMDatabase } from 'vuex-orm-decorators';
import { ICustomStore } from '@/lib/interfaces';

const isProduction = (process.env.NODE_ENV === 'production');
const strict = !isProduction;
const mutations = !isProduction ? { RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION } : undefined;
const plugins = [
  pathify.plugin,
  ORMDatabase.install({
    plugins: [VuexOrmPlugin],
  }),
  vuexPersist.plugin,
];

Vue.use(Vuex);

const store = new Store<ICustomStore>({
  modules,
  actions: {},
  mutations,
  plugins,
  strict,
});

export default store as Store<ICustomStore> & {
  set: (path: string, value: any) => void;
  get: <T = any>(path: string, ...args: any[]) => T;
  copy: <T = any>(path: string, ...args: any[]) => T;
  restored: boolean;
};
