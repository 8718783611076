import { BaseModel } from '@/models/internal';
import { AttrField, StringField, OrmModel, PrimaryKey, NumberField } from 'vuex-orm-decorators';

export enum AssetType {
  Image = 'image',
}

@OrmModel('assets')
export class Asset extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'Asset';
  }

  /**
   * Unique identifier of the Asset
   */
  @PrimaryKey()
  @NumberField()
  public id!: number;

  @StringField()
  public type!: AssetType;

  @NumberField()
  public assetable_id!: number;

  @StringField()
  public assetable_type!: string;

  @StringField()
  public url!: string;

  @AttrField({})
  public versions!: Record<string, string>;

  @AttrField({})
  public meta!: Record<string, any>;

  @StringField()
  public created_at!: string;

  @StringField()
  public updated_at!: string;

  /**
   * Get the smallest image available for this Asset
   */
  public smallestImage(): string | null {
    if (this.type.toLocaleLowerCase() !== AssetType.Image) return null;
    return this.versions.thumb ?? this.url;
  }

  /**
   * Get the largest image available for this Asset
   */
  public largestImage(): string | null {
    if (this.type.toLocaleLowerCase() !== AssetType.Image) return null;
    return this.url ?? this.versions.thumb;
  }
}

export default Asset;
