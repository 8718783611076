import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { UserService } from '@/services';
import { Utility } from '@/tools/Utility';
import routes from './routes';
import store from '@/store';
import ContextBarManager from '@/components/ContextBar/classes/ContextBarManager';

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL ?? '',
  routes,
});

Vue.use(VueRouter);

/**
 * Returns true if user is authenticated.
 *
 * @returns {boolean}
 */
function isAuthenticated(): boolean {
  return !!UserService.getInstance().getActive()?.authentication_token;
}

/**
 * Returns true if given route requires authentication.
 */
function isAuthenticationRequired(route: Route): boolean {
  return route.matched.some((record) => record.meta.requiresAuth);
}

/**
 * Performs a cascading check on whether or not a feature is enabled
 * for this instance of the application. If a upper-level feature is
 * disabled but a child of that feature is NOT, we will STILL disallow
 * access to the feature.
 * @param feature Name of feature
 * @returns Whether feature is enabled or not
 */
async function isFeatureEnabled(to: Route) {
  const config = await Utility.fetchConfig();
  return to.matched.every((match) => {
    const feature = match.meta?.feature;
    if (config.application?.features?.[feature]?.disabled) {
      return false;
    }
    return true;
  }, []);
}

/**
 * Update the page's meta data
 * @param to Route
 */
async function updateDocument(to: Route) {
  const config = await Utility.fetchConfig();
  const appName = config.portal.name_short;

  let prefix = '';
  switch (process.env.VUE_APP_ENV) {
    case 'development':
      prefix = `🛠️ ${appName} - `;
      break;
    case 'staging':
      prefix = `🎭 ${appName} - `;
      break;
    default:
      break;
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = `${prefix}${nearestWithTitle.meta.title}`;
  } else {
    document.title = `${prefix}${config.portal.name_short ?? config.portal.name}`;
  }
}

router.beforeEach(async (to, _from, next) => {
  const featuresEnabled = await isFeatureEnabled(to);
  if (!featuresEnabled) {
    return next({
      path: '/error',
      name: 'error',
      query: {
        code: '403',
        reason: 'Unauthorized',
        message: 'Unable to access this feature. Please contact support for more information.',
        hideHomeButton: '1',
      },
    });
  }

  // Give our route a chance to specify it's layout component before
  // rendering a default
  store.set('layout/name', to.meta?.layout ?? 'main');

  if (Utility.isUsingIEBrowser() && to.name !== 'unsupported-browser') {
    return next({
      name: 'unsupported-browser',
    });
  }

  if (isAuthenticationRequired(to)) {
    // not authenticated; go to login
    if (!isAuthenticated()) {
      const route = {
        name: 'login',
        params: to.params,
        query: {
          redirect: to.path,
        },
      };
      return next(route);
    }
  }

  // Clear out existing actions from the context bar so each view/page
  // can properly redefine them on their own.
  ContextBarManager.clearActions();

  // Modify document meta data and page title
  updateDocument(to);

  return next();
});

export default router;
