import { RouteConfig } from 'vue-router/types/router';
import dashboard from './dashboard';
import reports from './reports';
import docs from './docs';
import exceptions from './exceptions';

const routes: Array<RouteConfig> = [
  ...dashboard,
  ...reports,
  ...docs,
  ...exceptions,
];

export default routes;
