import { AxiosResponse, AxiosRequestConfig } from 'axios';
import {
  PREFIX_STYLE,
  CLEAR_STYLE,
  INFO_STYLE,
  EVENT_STYLE,
  REQ_STYLE,
  RES_STYLE,
  WARN_STYLE,
  ERROR_STYLE,
  SILLY_STYLE,
  SILLY_STYLE_BODY,
} from './styles';

const DEBUG = (process.env.VUE_APP_ENV !== 'production' || !!process.env.VUE_APP_DEBUG);

type LoggerConstructorOptions = {
  /**
   * Context from which logger is being instantiated (i.e which component/file/etc.)
   */
  context: string;
  /**
   * Whether logger is in debug mode (defaults to whether the `VUE_APP_ENV` is set to `production`)
   */
  debug?: boolean;
};

/**
 * Basic Logger
 */
export class Logger {
  /**
   * Debug flag turns logging on/off
   */
  private debug: boolean = DEBUG;

  /**
   * Context of where logger instance is being used
   */
  private readonly context: string = 'Logger';

  /**
   * Constructor
   */
  public constructor(options?: LoggerConstructorOptions) {
    if (typeof options?.debug !== 'undefined') {
      this.debug = options.debug;
    }
    if (typeof options?.context !== 'undefined') {
      this.context = options.context;
    }
  }

  /**
   * Silly
   *
   * @param input
   */
  public static silly(input: any, context?: string) {
    const message = Logger.stringify(input);

    window.console.info(
      `%c${context}%cSilly%c ${message}%c`,
      PREFIX_STYLE,
      SILLY_STYLE,
      SILLY_STYLE_BODY,
      CLEAR_STYLE,
    );
  }

  /**
   * Info
   *
   * @param input
   */
  public static info(input: any, context?: string) {
    const message = Logger.stringify(input);

    window.console.info(
      `%c${context}%cInfo%c ${message}`,
      PREFIX_STYLE,
      INFO_STYLE,
      CLEAR_STYLE,
    );
  }

  /**
   * Error
   *
   * @param input
   */
  public static error(input: any, context?: string) {
    const message = Logger.stringify(input);

    window.console.error(
      `%c${context}%cError%c ${message}`,
      PREFIX_STYLE,
      ERROR_STYLE,
      CLEAR_STYLE,
    );
  }

  /**
   * Warning
   *
   * @param input
   */
  public static warn(input: any, context?: string) {
    const message = Logger.stringify(input);

    window.console.warn(
      `%c${context}%cWarning%c ${message}`,
      PREFIX_STYLE,
      WARN_STYLE,
      CLEAR_STYLE,
    );
  }

  /**
   * Event
   *
   * @param input
   */
  public static event(input: any, context?: string) {
    const message = Logger.stringify(input);

    window.console.info(
      `%c${context}%cEvent%c ${message}`,
      PREFIX_STYLE,
      EVENT_STYLE,
      CLEAR_STYLE,
    );
  }

  /**
   * Request
   */
  public static request(config: AxiosRequestConfig, context?: string) {
    window.console.groupCollapsed(
      `%c${context}%cRequest%c ${config.method} %c: ${config.url}`,
      PREFIX_STYLE,
      REQ_STYLE,
      'font-weight: bold;',
      CLEAR_STYLE,
    );
    window.console.info(config);
    window.console.groupEnd();
  }

  /**
   * Response
   */
  public static response(response: AxiosResponse<any>, context?: string) {
    window.console.groupCollapsed(
      `%c${context}%cResponse%c ${response.config.method?.toLocaleUpperCase()} %c: ${response.config.url}`,
      PREFIX_STYLE,
      RES_STYLE,
      'font-weight: bold;',
      CLEAR_STYLE,
    );
    window.console.info({
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    });
    window.console.groupEnd();
  }

  /**
   * Stringify with respect to formatting.
   *
   * @param {any} input
   * @returns {string} Output
   */
  private static stringify(input: any) {
    if (input instanceof Error) {
      const obj = {
        ...input,
        name: input.name,
        message: input.message,
      };
      return JSON.stringify(obj, null, 2);
    }

    if (typeof input === 'string') {
      return input;
    }

    return JSON.stringify(input, null, 2);
  }

  /**
   * Silly
   *
   * @param input
   */
  public silly(input: any) {
    if (!this.debug) {
      return;
    }
    return Logger.silly(input, this.context);
  }

  /**
   * Info
   *
   * @param input
   */
  public info(input: any) {
    if (!this.debug) {
      return;
    }
    return Logger.info(input, this.context);
  }

  /**
   * Error
   *
   * @param input
   */
  public error(input: any) {
    if (!this.debug) {
      return;
    }
    return Logger.error(input, this.context);
  }

  /**
   * Warning
   *
   * @param input
   */
  public warn(input: any) {
    if (!this.debug) {
      return;
    }
    return Logger.warn(input, this.context);
  }

  /**
   * Event
   *
   * @param input
   */
  public event(input: any) {
    if (!this.debug) {
      return;
    }
    return Logger.event(input, this.context);
  }

  /**
   * Request
   */
  public request(config: AxiosRequestConfig) {
    if (!this.debug) {
      return;
    }
    return Logger.request(config, this.context);
  }

  /**
   * Response
   */
  public response(response: AxiosResponse<any>) {
    if (!this.debug) {
      return;
    }
    return Logger.response(response, this.context);
  }
}

export default Logger;
