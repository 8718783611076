import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { RouteConfig } from 'vue-router/types/router';

const config: RouteConfig[] = [
  {
    path: 'users',
    component: () => import(/* webpackChunkName: "Users" */ '@/views/Dashboard/Users/Users.vue'),
    meta: {
      requiresAuth: true,
      breadcrumbs: [{
        text: 'Users',
        to: { name: 'users-list' },
      }],
    },
    children: [
      {
        path: 'list',
        name: 'users-list',
        component: () => import(/* webpackChunkName: "Users" */ '@/views/Dashboard/Users/UsersList/UsersList.vue'),
        meta: {
          title: 'List Users',
          subtitle: 'Browse through all the accessible user records that exist within our system.',
        },
      },
      {
        path: 'directorsoncall',
        name: 'directors-on-call-list',
        component: () => import(/* webpackChunkName: "Users" */ '@/views/Dashboard/Users/DirectorsOnCallList.vue'),
        meta: {
          title: 'Directors On-Call',
          subtitle: `
            Manage "on call" status. Directors on call will receive email notifications for any Orders
            with a "rush" or "emergency" priority requiring approval.
          `,
          breadcrumbs: [{
            text: 'Directors',
          }] as RouterBreadcrumb[],
        },
      },
    ],
  },
  {
    path: 'users/invitation/accept',
    name: 'accept-invite',
    meta: {
      requiresAuth: false,
      layout: 'full-screen',
    },
    props: (route) => ({
      name: route.query.name,
      invitationToken: route.query.invitation_token,
    }),
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/Dashboard/Auth/AcceptInvitationForm.vue'),
  },
];

export default config;
