import { defineAbility as caslDefineAbility } from '@casl/ability';
import { Order, Customer, User, Auth, Collection, Product } from '@/models/internal';
import { OrderStatus, ApprovalStatus } from '@/lib/enum';
import { AppAbility } from '@/lib/types';

export enum UserRole {
  Admin = 'admin',
  ProjectAdmin = 'project_admin',
  SiteUser = 'site_user',
  ContractAdvisor = 'contract_advisor',
  Helpdesk = 'helpdesk',
  ProfessionalPractice = 'professional_practice',
  SwabLead = 'swab_lead',
  OccHealthLead = 'occ_health_lead',
  Management = 'management',
  Finance = 'finance',
}

/**
 * Define a User's abilities via their roles on the Auth
 * object
 * @param auth
 */
export default function defineAbility(auth: Auth) {
  return caslDefineAbility<AppAbility>((can, cannot) => {
    // If there aren't any roles for this auth, don't apply any conditions
    // because there's no one logged in and all permissions will be false
    // anyways.
    if (auth.roles.length === 0) return;

    auth.roles.forEach((role: string) => {
      switch (role) {
      // admin & project_admin are interchangeable roles currently
        case UserRole.Admin:
        case UserRole.ProjectAdmin: {
          can('manage', 'all');
          break;
        }
        case UserRole.SiteUser: {
          can(['read', 'create'], Order);
          can('manage_part_a', Order);
          can('manage_part_c', Order);
          break;
        }
        case UserRole.ContractAdvisor: {
          can(['read', 'create'], Order);
          can(['update', 'review'], Order, {
            status: OrderStatus.Submitted,
          });
          can('manage_part_a', Order);
          can('manage_part_b', Order);
          can('manage_part_c', Order);
          can('order_for', User);
          break;
        }
        case UserRole.Helpdesk: {
          can('manage', Customer);
          can('manage', User);
          can('manage', Product);
          can('manage', Collection);

          can(['read', 'create'], Order);
          can(['update', 'approve'], Order, {
            status: OrderStatus.Reviewed,
          });
          can('manage_part_a', Order);
          can('manage_part_b', Order);
          can('manage_part_c', Order);
          can('read', 'Report');
          can('order_for', User);
          break;
        }
        case UserRole.ProfessionalPractice: {
          can('read', Order);
          can('update', Order, {
            status: OrderStatus.Approved,
            'approvals.part_a': ApprovalStatus.Pending,
            'section_counts': {
              $elemMatch: {
                name: 'part_a',
                total_units: { $gt: 0 },
              },
            },
          });
          can('approve_part_a', Order, {
            status: OrderStatus.Approved,
            'section_counts': {
              $elemMatch: {
                name: 'part_a',
                total_units: { $gt: 0 },
              },
            },
          });
          can('manage_part_a', Order);
          // Hybrid helpdesk/PP role should be able to approve the Order
          // and approve_part_a in same step (ie. approve_part_a from 'reviewed' order status)
          if (auth.roles.includes(UserRole.Helpdesk)) {
            can('update', Order, {
              status: {
                $in: [
                  OrderStatus.Reviewed,
                  OrderStatus.Approved,
                ],
              },
              'approvals.part_a': ApprovalStatus.Pending,
              'section_counts': {
                $elemMatch: {
                  name: 'part_a',
                  total_units: { $gt: 0 },
                },
              },
            });
            can ('approve_part_a', Order, {
              status: {
                $in: [
                  OrderStatus.Reviewed,
                  OrderStatus.Approved,
                ],
              },
              'section_counts': {
                $elemMatch: {
                  name: 'part_a',
                  total_units: { $gt: 0 },
                },
              },
            });
          }
          can('read', 'Report');
          can('order_for', User);
          break;
        }
        case UserRole.SwabLead: {
          can('read', Order);
          can('update', Order, {
            status: OrderStatus.Approved,
            'approvals.part_b': ApprovalStatus.Pending,
            'section_counts': {
              $elemMatch: {
                name: 'part_b',
                total_units: { $gt: 0 },
              },
            },
          });
          can('approve_part_b', Order, {
            status: OrderStatus.Approved,
            'section_counts': {
              $elemMatch: {
                name: 'part_b',
                total_units: { $gt: 0 },
              },
            },
          });
          can('manage_part_b', Order);
          break;
        }
        case UserRole.OccHealthLead: {
          can('read', Order);
          can('update', Order, {
            status: OrderStatus.Approved,
            'approvals.part_c': ApprovalStatus.Pending,
            'section_counts': {
              $elemMatch: {
                name: 'part_c',
                total_units: { $gt: 0 },
              },
            },
          });
          can('approve_part_c', Order, {
            status: OrderStatus.Approved,
            'section_counts': {
              $elemMatch: {
                name: 'part_c',
                total_units: { $gt: 0 },
              },
            },
          });
          can('manage_part_c', Order);
          // Hybrid helpdesk/PP role should be able to approve the Order
          // and approve_part_a in same step (ie. approve_part_a from 'reviewed' order status)
          if (auth.roles.includes(UserRole.Helpdesk)) {
            can('update', Order, {
              status: {
                $in: [
                  OrderStatus.Reviewed,
                  OrderStatus.Approved,
                ],
              },
              'approvals.part_c': ApprovalStatus.Pending,
              'section_counts': {
                $elemMatch: {
                  name: 'part_c',
                  total_units: { $gt: 0 },
                },
              },
            });
            can ('approve_part_c', Order, {
              status: {
                $in: [
                  OrderStatus.Reviewed,
                  OrderStatus.Approved,
                ],
              },
              'section_counts': {
                $elemMatch: {
                  name: 'part_c',
                  total_units: { $gt: 0 },
                },
              },
            });
          }
          can('read', 'Report');
          break;
        }
        case UserRole.Management: {
          can('read', Order);
          can('read', 'Report');
          break;
        }
        case UserRole.Finance: {
          can('read', Order);
          can('read', 'Report');
          break;
        }
        default: {
          break;
        }
      }
    });

    /**
     * Final-Say abilities
     */
    cannot('update', Collection, { mutable: false });
    cannot('destroy', Collection, { mutable: false });

    cannot('review', Order, {
      status: { $ne: OrderStatus.Submitted },
    });

    cannot('approve', Order, {
      status: { $ne: OrderStatus.Reviewed },
    });

    cannot([
      'approve_part_a',
      'approve_part_b',
      'approve_part_c',
    ], Order, {
      status: {
        $nin: [
          OrderStatus.Reviewed,
          OrderStatus.Approved,
        ],
      },
    });

    // Cannot approve/deny Order parts A-C when already denied
    cannot('approve_part_a', Order, {
      'approvals.part_a': {
        $in: [
          ApprovalStatus.Denied,
          ApprovalStatus.Approved,
        ],
      },
    });
    cannot('approve_part_b', Order, {
      'approvals.part_b': {
        $in: [
          ApprovalStatus.Denied,
          ApprovalStatus.Approved,
        ],
      },
    });
    cannot('approve_part_c', Order, {
      'approvals.part_c': {
        $in: [
          ApprovalStatus.Denied,
          ApprovalStatus.Approved,
        ],
      },
    });

    cannot([
      'approve',
      'approve_part_a',
      'approve_part_b',
      'approve_part_c',
      'update',
    ], Order, {
      status: {
        $in: [
          OrderStatus.Denied,
          OrderStatus.Complete,
        ],
      },
    });

    // Only allow approvals for 'pending' Customer
    cannot('approve', Customer, {
      approval_status: {
        $in: [
          ApprovalStatus.Approved,
          ApprovalStatus.Denied,
          ApprovalStatus.NotSet,
        ],
      },
    });
  });
}
