


























import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import NavigationItems from '@/components/NavigationDrawer/NavigationItems.vue';
import links from '@/components/NavigationDrawer/links';

@Component({
  name: 'NavigationDrawer',
  components: {
    NavigationItems,
  },
})
export class NavigationDrawer extends Vue {
  @Prop({ required: true })
  protected readonly value!: boolean;

  protected drawer = this.value;

  protected selected = '';

  protected get isMobile(): boolean {
    return this.$vuetify.breakpoint.name === 'xs';
  }

  protected get navItems() {
    return links.filter((link) => link.access ? this.$ability.can(link.access[0] as any, link.access[1]) : true);
  }

  @Watch('value')
  protected watchValue(value: boolean) {
    this.drawer = value;
  }

  protected onToggleDrawer(isOpen: boolean) {
    this.$emit('input', isOpen);
  }

  protected onItemClick(event: any, item: any) {
    if (event) {
      this.selected = item;
    }
  }
}

export default NavigationDrawer;
