import { CustomerProduct } from '@/models/CustomerProduct';
import { ModelService } from '@/services/ModelService';
import {
  IFindOneArguments,
  IUpdateArguments,
} from '@/lib/interfaces';
import { prepareData } from '@/models/internal';
import { ProductService } from './ProductService';

/**
 * Service class for interacting with the Customer model
 */
export class CustomerProductService extends ModelService<typeof CustomerProduct> {
  /**
   * Cached instance of the service
   */
  private static instance: CustomerProductService | null = null;

  protected model = CustomerProduct;

  protected path = '/customers';

  private constructor() {
    super();
  }

  /**
   * Get an instance of the CustomerProductService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new CustomerProductService();
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Function for mapping data during the `prepareData` phase of record
   * creation/updating
   *
   * If this method isn't defined, it no longer get's used in the pipeline
   *
   * @param data
   */
  public static mapData(data: any) {
    return {
      id: data.id,
      created_at: data.created_at,
      updated_at: data.updated_at,
      meta: data.meta,
      customer_id: data.customer_id,
      product_id: data.product_id,
      product: data.product? prepareData(data.product, ProductService.mapData) : null,
    };
  }

  public get api() {
    return {
      /**
       * Find a list of CustomerProducts (for a Customer) on the server via GET request
       */
      find: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}/products`, args);
        return data;
      },

      /**
       * Update an existing CustomerProduct on the server via PATCH request
       */
      update: async (args: ICustomerProductUpdateArguments) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}/product/${args.customer_product.id}`, args);
        return data;
      },
    };
  }
}

interface ICustomerProductUpdateArguments extends IUpdateArguments {
  customer_product: {
    id: number;
    meta: {
      auto_approval_quantity_limit: number;
    }
  };
}
