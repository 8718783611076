
































import { Vue, Component, Watch, Prop, Ref } from 'vue-property-decorator';
import { UserService } from '@/services/UserService';
import { Sync } from 'vuex-pathify';
import UserIcon from './User/UserIcon.vue';

@Component({
  name: 'AppBar',
  components: {
    UserIcon,
  },
})
export default class AppBar extends Vue {
  @Sync('layout/appBarHeight')
  protected appBarHeight!: number;

  @Prop({ required: true, default: false })
  private readonly value!: boolean;

  @Ref('bar')
  private readonly barRef!: Vue;

  protected drawer = this.value;

  private readonly userService: UserService = UserService.getInstance();

  protected get user() {
    return this.userService.getActive();
  }

  protected get height(): number {
    switch (this.$vuetify.breakpoint.name) {
      case 'sm':
      case 'xs':
        return 48;
      default:
        return 56;
    }
  }

  @Watch('value')
  protected watchValue(v: boolean) {
    this.drawer = v;
  }

  public mounted() {
    window.addEventListener('resize', this.onResize);
    this.$nextTick(this.onResize);
  }

  public destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  protected onToggleDrawer(isOpen: boolean) {
    this.$emit('input', isOpen);
  }

  private onResize() {
    this.appBarHeight = this.barRef.$el.getBoundingClientRect().height;
  }
}
