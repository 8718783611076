import { Logger } from '@/tools/Logger';
/**
 * Prepare a payload's data for inserting/updating records using specified
 * mapping function
 * @param payload
 * @param mapping Callback for use with map function
 */
export default function prepareData(data: any, mapping: (d: any) => any) {
  if (!data) throw Error('Unable to prepare data for model without `data` parameter');
  if (!mapping) throw Error('Unable to prepare data for model with `mapping` function parameter');
  const isArray = Array.isArray(data);

  if (!isArray) data = [data];

  let newData = null;
  try {
    newData = data.map(mapping);
  } catch (error) {
    new Logger({ context: 'prepareData' }).error(error.message || 'Error preparing data');
  }

  if (isArray) return newData;
  return newData[0];
}
