import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: 'products',
    component: () => import(/* webpackChunkName: "Products" */ '@/views/Dashboard/Products/Products.vue'),
    meta: {
      requiresAuth: true,
      breadcrumbs(this: Vue): RouterBreadcrumb[] {
        return [{
          text: 'Products',
          to: {
            name: 'products-list',
          },
        }];
      },
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        name: 'products-list',
        component: () => import(/* webpackChunkName: "Products" */ '@/views/Dashboard/Products/ProductsList.vue'),
        meta: {
          title: 'List Products',
          subtitle: 'Browse a list of products. Interact with them using the available controls.',
        },
      },
      {
        path: ':id',
        name: 'products-view',
        component: () => import(/* webpackChunkName: "Products" */ '@/views/Dashboard/Products/ProductsView.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'View Product',
          subtitle: 'Preview details for this Product such as name, SKU, and specifications',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: this.$route.params.id,
            }];
          },
        },
      },
      {
        path: ':id/edit',
        name: 'products-edit',
        component: () => import(/* webpackChunkName: "Products" */ '@/views/Dashboard/Products/ProductsEdit.vue'),
        props: (route) => ({
          id: route.params.id,
          fromRouteName: route.params.fromRouteName,
        }),
        beforeEnter: (to, from, next) => {
          if (to.params.id) {
            to.params.fromRouteName = from.name ?? '';
            return next();
          }
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'Edit Product',
          subtitle: 'Modify the details and data associated with this product.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: this.$route.params.id,
              href: `/dashboard/products/${this.$route.params.id}`,
            }, {
              text: 'Edit',
              href: `/dashboard/products/${this.$route.params.id}/edit`,
            }];
          },
        },
      },
    ],
  },
];

export default config;
