import { VSnackbar, VIcon } from 'vuetify/lib';
import { CreateElement } from 'vue';

/**
 * Helper function for rendering a VAlert
 */
export function notify(this: Vue, config?: NotifyConfig) {
  return function createNotification(
    this: Vue,
    /**
     * Text to display inside notification's body
     */
    text: string,
    /**
     * Type of notification message (determines color and icon)
     */
    color: string,
    /**
     * Options object for rendering the notification (Vuetify API wrapper for `v-notification`)
     */
    options?: NotifyOptions,
  ) {
    const propsData = {
      value: true,
      color: color ?? 'blue-grey lighten-4',
      timeout: options?.timeout ?? 5000,
      icon: options?.icon,
    };

    if (!propsData.icon) propsData.icon = 'mdi-information';
    if (color === 'success') propsData.icon = 'mdi-check-circle';
    if (color === 'error') propsData.icon = 'mdi-close-circle';
    if (color === 'warning') propsData.icon = 'mdi-alert-circle';

    const instance = new VSnackbar({
      parent: this,
      propsData,
      components: {
        VIcon,
      },
      render: function(createElement: CreateElement) {
        const children = [
          createElement('v-icon', { props: {} }, [propsData.icon]),
          text,
        ];
        return createElement('v-snackbar', { props: propsData }, children);
      },
    });

    instance.$mount();

    const containerEl = document.getElementById(config?.el);

    const element: Element = options?.el ?? containerEl;
    if (!element) throw Error('Genify: Invalid `el` passed to `createAlert` function');

    element.prepend(instance.$el);
  };
}

export type NotifyOptions = {
  /**
   * Element to append notification to. Defaults to attaching to element this is called from.
   */
  el?: any;
  /**
   * Time (in milliseconds) to wait until snackbar is automatically hidden. Use -1 to keep open
   * indefinitely (0 in version < 2.3 ). It is recommended for this number to be between 4000
   * and 10000. Changes to this property will reset the timeout.
   */
  timeout?: number;
  /**
   * Designates a specific icon.
   */
  icon?: string;
  /**
   * Sets the component transition.
   */
  transition?: 'scale-transition'|'fab-transition'|'fade-transition'|'expand-transition'|'scale-transition'|
  'scroll-x-transition'|'scroll-x-reverse-transition'|'scroll-y-transition'|'scroll-y-reverse-transition'|
  'slide-x-transition'|'slide-x-reverse-transition'|'slide-y-transition'|'slide-y-reverse-transition'|
  'snack-transition';
};

export type NotifyConfig = Record<string, any>;
