import { BaseModel, Customer, Product } from '@/models/internal';
import { AttrField, StringField, OrmModel, PrimaryKey, BooleanField, HasManyByField, HasManyField, BelongsToField } from 'vuex-orm-decorators';

@OrmModel('collections')
export class Collection extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'Collection';
  }

  @PrimaryKey()
  @AttrField()
  public id!: number;

  @StringField()
  public name!: string;

  @StringField()
  public code!: string;

  @BooleanField(false)
  public mutable!: boolean;

  @StringField()
  public created_at!: string;

  @StringField()
  public updated_at!: string;

  @AttrField({})
  public meta!: any;

  @AttrField()
  public blacklist_parent_collection_id!: number;

  @HasManyByField(Customer, 'customer_ids')
  public customers!: Customer[];

  @HasManyByField(Product, 'product_ids')
  public products!: Product[];

  @BelongsToField(Collection, 'blacklist_parent_collection_id')
  public blacklist_parent_collection!: Collection;

  @HasManyField(Collection, 'blacklist_parent_collection_id')
  public blacklist_collections!: Collection[];

  @AttrField([])
  public product_ids!: Array<number>;

  @AttrField([])
  public customer_ids!: Array<number>;
}

export default Collection;
