import { BaseModel } from '@/models/internal';
import { Item } from '@vuex-orm/core/lib/data';
import { OrmModel, AttrField, PrimaryKey, BelongsToField, StringField, NumberField } from 'vuex-orm-decorators';
import Product from './Product';

@OrmModel('orders_items')
export class OrderItem extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'OrderItem';
  }

  @PrimaryKey('product_id')
  @AttrField()
  public order_id!: string | number;

  @AttrField()
  public product_id!: string | number;

  @NumberField()
  public quantity!: number;

  @StringField()
  public form_section!: string;

  @BelongsToField(Product, 'product_id')
  public product!: Product;

  /**
   * Get the Product record from the OrderItem or null if it
   * doesn't exist.
   */
  public getProduct(): Item<Product> {
    if (this.product) return this.product;

    const product = Product.find(this.product_id);

    if (product) {
      this.product = product;
      return this.product;
    }

    return null;
  }
}

export default OrderItem;
