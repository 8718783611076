import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: 'login',
    name: 'login',
    meta: {
      layout: 'full-screen',
    },
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/Dashboard/Auth/LoginForm.vue'),
  },
  {
    path: 'logout',
    name: 'logout',
    meta: {
      layout: 'full-screen',
    },
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/Dashboard/Auth/Logout.vue'),
  },
  // {
  //   path: 'register',
  //   name: 'register',
  //   meta: {
  //     layout: 'full-screen',
  //   },
  //   component: () => import(/* webpackChunkName: "Auth" */ '@/views/Dashboard/Auth/RegisterForm.vue'),
  // },
  {
    path: 'confirmation',
    name: 'register-confirmation',
    meta: {
      layout: 'full-screen',
    },
    props: (route) => ({
      confirmationToken: route.query.confirmation_token,
    }),
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/Dashboard/Auth/ConfirmRegistration.vue'),
  },
  {
    path: 'forgotpassword',
    name: 'forgot-password',
    meta: {
      layout: 'full-screen',
    },
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/Dashboard/Auth/ForgotPasswordForm.vue'),
  },
  {
    path: 'resetpassword',
    name: 'reset-password',
    meta: {
      layout: 'full-screen',
    },
    props: (route) => ({
      resetPasswordToken: route.query.reset_password_token,
    }),
    component: () => import(/* webpackChunkName: "Auth" */ '@/views/Dashboard/Auth/ResetPasswordForm.vue'),
  },
];

export default config;
