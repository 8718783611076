import * as Sentry from '@sentry/vue';
import User from '@/models/User';
import merge from 'lodash/merge';
import omitDeep from 'omit-deep-lodash';
import { VueOptions } from '@sentry/vue/dist/sdk';
import { Integrations } from '@sentry/tracing';
import axios, { AxiosError } from 'axios';

const dsn = process.env.VUE_APP_SENTRY_DSN ?? '';
const isProduction = ['staging', 'production'].includes(process.env.VUE_APP_ENV ?? '');

/**
 * Service to house logic for sending requests to the API
 */
export class SentryService {
  private static instance: SentryService | null = null;

  private readonly client = Sentry;

  private readonly enabled = (dsn.length > 0 && isProduction);

  private readonly defaultConfig: Partial<VueOptions> = {
    dsn,
    enabled: this.enabled,
    environment: process.env.VUE_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    logErrors: true,
    attachProps: true,
    maxBreadcrumbs: 10,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    beforeSend: this.beforeSend,
  };

  private constructor() {/**/}

  /**
   * Get the singleton instance of this class
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new SentryService();
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Initialize our Sentry client
   * @param options Sentry's VueOptions
   */
  public initialize(options: Partial<VueOptions> = {}) {
    if (!this.enabled) return;

    this.client.init(
      merge(this.defaultConfig, options),
    );
  }

  /**
   * Configure Sentry's scope for what is captured when errors/events occur
   * @param scope Sentry scope
   */
  public configureUser(user: User | null) {
    if (!user) {
      return this.client.configureScope(scope => scope.setUser(null));
    }
    return this.client.configureScope(scope => scope.setUser({
      email: user.email,
      id: String(user.id),
      username: user.name,
      ip_address: '{{auto}}',
      roles: user.roles,
      dist_channels: user.dist_channels,
      assigned_sites: user.assigned_sites,
      access_locked: user.access_locked,
      is_on_call: user.is_on_call,
      permissions: JSON.stringify(user.permissions),
    }));
  }

  /**
   * Getter for Sentry client
   * @returns Sentry client
   */
  public getClient() {
    return this.client;
  }

  /**
   * Capture an exception and send it to Sentry. If an AxiosError is passed
   * in, we'll attempt to extract/redact certain information from it before
   * sending to Sentry.
   * @param error Error
   * @returns cleaned Error
   */
  public captureException(error: Error) {
    // Check if the error is a regular error or an Axios error
    if (!axios.isAxiosError(error)) {
      this.client.captureException(error);
      return error;
    }

    const cleanError = this.cleanAxiosError(error);

    this.client.captureException(cleanError, {
      contexts: {
        request: {
          value: JSON.stringify(cleanError.config) ?? null,
        },
        response: {
          value: JSON.stringify(cleanError.response),
        },
        exception: {
          value: JSON.stringify({
            name: cleanError.name,
            message: cleanError.message,
            stack: cleanError.stack,
          }),
        },
      },
    });

    return cleanError;
  }

  /**
   * Clean an Axios error of it's sensitive information.
   * @param error Dirty AxiosError
   * @returns Cleaned AxiosError
   */
  private cleanAxiosError(error: AxiosError): AxiosError {
    const sensitive = ['password', 'access_token'];
    const clean = (obj: any): any => omitDeep(obj, ...sensitive) ?? {};

    if (error.request) {
      error.request = clean(error.request);
    }

    if (error.response) {
      error.response = clean(error.response);
      // We already have request data included on the error, we can just get rid
      // of this for the sake of not having to clean it like we do the `config`
      // field below.
      if (error.response?.request) delete error.response.request;

      if (error.response?.config.data) {
        error.response.config.data = JSON.parse(error.response.config.data ?? {});
        error.response.config.data = clean(error.response.config.data);
        error.response.config.data = JSON.stringify(error.response.config.data);
      }
    }

    // We know the `data` attribute on the config object holds stringified
    // data about the original request, which could contain sensitive
    // information. So, let's manually strip it out after parsing it back
    // to life, then send it back to Stringsville.
    if (error.config.data) {
      error.config.data = JSON.parse(error.config.data ?? {});
      error.config.data = clean(error.config.data);
      error.config.data = JSON.stringify(error.config.data);
    }

    return error;
  }

  /**
   * Callback before Sentry sends an event to it's servers.
   * @param event Sentry event
   * @returns Sentry event
   */
  private beforeSend(event: any /* hint: any */) {
    return event;
  }
}
