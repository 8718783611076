















import { Vue, Component, Prop } from 'vue-property-decorator';
import type { NavigationLink } from './links';

@Component({
  name: 'NavigationItem',
})
export class NavigationItem extends Vue {
  @Prop({ required: true })
  protected readonly item!: NavigationLink;
}

export default NavigationItem;
