import { AuthService } from '@/services/AuthService';
import { RouteConfig } from 'vue-router/types/router';

const authService = AuthService.getInstance();

const config: Array<RouteConfig> = [
  {
    path: '',
    redirect: 'login',
  },
  {
    path: 'home',
    name: 'home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/Dashboard/Home.vue'),
    beforeEnter: (to, from, next) => {
      if (authService.getAbility().can('read', 'Report')) {
        return next();
      }
      return next({ name: 'orders-list' });
    },
    meta: {
      requiresAuth: true,
      title: 'Dashboard',
      subtitle: 'Welcome to the Vancouver Coastal Health PPE Order-form dashboard. \
      Get started with the navigation drawer located on the left - or have a look at one of the reports below!',
    },
  },
];

export default config;
