export enum ApprovalStatus {
  Pending = 'pending',
  Reviewed = 'reviewed',
  Approved = 'approved',
  Denied = 'denied',
  NotSet = '',
}

export enum OrderStatus {
  Submitted = 'submitted',
  Reviewed = 'reviewed',
  Approved = 'approved',
  Denied = 'denied',
  Complete = 'complete',
  Shipped = 'shipped',
}

export enum OrderType {
  Standard = 'standard',
  Rush = 'rush',
  Emergency = 'emergency',
}

export enum FulfillmentStatus {
  Submitted = 'submitted',
  Created = 'created',
  ToBeCancelled = 'to_be_cancelled',
  Cancelled = 'cancelled',
  Pending = 'pending',
  Changed = 'changed',
  BeingPacked = 'being_packed',
  Blocked = 'blocked',
  Packed = 'packed',
  Holding = 'holding',
  Released = 'released',
  BeingShipped = 'being_shipped',
  Shipped = 'shipped',
  Complete = 'complete',
}
