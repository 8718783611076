import { BaseModel } from '@/models/internal';
import { AttrField, BelongsToField, OrmModel, PrimaryKey } from 'vuex-orm-decorators';
import Customer from './Customer';
import Product from './Product';

@OrmModel('customer-products')
export class CustomerProduct extends BaseModel {
  public static get modelName() {
    return 'CustomerProduct';
  }

  @PrimaryKey()
  @AttrField()
  public id!: number;

  @AttrField(null)
  public created_at!: string;

  @AttrField(null)
  public updated_at!: string;

  @AttrField({})
  public meta!: Record<string, any>;

  @AttrField()
  public customer_id!: number;

  @AttrField()
  public product_id!: number;

  @BelongsToField(Product, 'product_id')
  public product!: Product;

  @BelongsToField(Customer, 'customer_id')
  public customer!: Customer;
}

export default CustomerProduct;
