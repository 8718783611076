import { ICustomStore } from '@/lib/interfaces';
import VuexPersistence from 'vuex-persist';

export default new VuexPersistence<ICustomStore>({
  storage: window.localStorage,
  modules: [
    'entities',
    'context',
  ],
});
