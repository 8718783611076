import { VAlert } from 'vuetify/lib';
import { CreateElement, VNodeChildrenArrayContents } from 'vue';

/**
 * Helper function for rendering a VAlert
 */
export function alert(this: Vue, config?: AlertConfig) {
  return async function createAlert(
    this: Vue,
    /**
     * Text to display inside alert's body
     */
    text: string | string[],
    /**
     * Type of alert message (determines color and icon)
     */
    type: 'success'|'error'|'warning'|'info',
    /**
     * Options object for rendering the alert (Vuetify API wrapper for `v-alert`)
     */
    options?: AlertOptions,
  ) {
    const propsData = {
      type: type,
      transition: options?.transition,
      prominent: options?.prominent,
      dismissible: options?.dismissible ?? true,
    };

    const instance = new VAlert({
      parent: this,
      propsData,
      render: function(createElement: CreateElement) {
        const childNodes: VNodeChildrenArrayContents = [];

        if (Array.isArray(text)) {
          text.forEach((t) => {
            childNodes.push(createElement('div', t));
          });
        } else {
          childNodes.push(createElement('div', text));
        }

        return createElement('v-alert', { props: propsData }, childNodes);
      },
    });

    instance.$mount();

    const containerEl = document.getElementById(config?.el);

    const element = options?.el ?? containerEl;
    if (!element) throw Error('Genify: Invalid `el` passed to `createAlert` function');

    element.appendChild(instance.$el);

    await this.$vuetify.goTo(instance.$el as any, {
      easing: 'easeInOutQuint',
      offset: 76,
    });
  };
}

export type AlertOptions = {
  /**
   * Element to append alert to. Defaults to attaching to element this is called from.
   */
  el?: any;
  /**
   * Designates a specific icon.
   */
  icon?: string;
  /**
   * Sets the component transition.
   */
  transition?: 'scale-transition'|'fab-transition'|'fade-transition'|'expand-transition'|'scale-transition'|
  'scroll-x-transition'|'scroll-x-reverse-transition'|'scroll-y-transition'|'scroll-y-reverse-transition'|
  'slide-x-transition'|'slide-x-reverse-transition'|'slide-y-transition'|'slide-y-reverse-transition';
  /**
   * Displays a larger vertically centered icon to draw more attention.
   */
  prominent?: boolean;
  /**
   * Adds a close icon that can hide the alert.
   */
  dismissible?: boolean;
};

export type AlertConfig = Record<string, any>;
