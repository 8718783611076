import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { Order } from '@/models/Order';
import { Route, RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: 'orders',
    component: () => import(/* webpackChunkName: "Orders" */ '@/views/Dashboard/Orders/Orders.vue'),
    meta: {
      requiresAuth: true,
      breadcrumbs(this: Vue): RouterBreadcrumb[] {
        return [{
          text: 'Orders',
          to: {
            name: 'orders-list',
          },
        }];
      },
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        name: 'orders-list',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Dashboard/Orders/OrdersList/OrdersList.vue'),
        meta: {
          title: 'List Orders',
          subtitle: 'Browse a list of order records. Interact with them using the available controls.',
        },
      },
      {
        path: 'setup',
        name: 'orders-setup',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Dashboard/Orders/OrdersSetup.vue'),
        meta: {
          title: 'Setup Order',
          subtitle: 'Define the relevant parameters for the creation of your new order.',
          breadcrumbs: [{
            text: 'Setup',
            disabled: true,
          }] as RouterBreadcrumb[],
        },
      },
      {
        path: 'create',
        name: 'orders-create',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Dashboard/Orders/OrdersCreate.vue'),
        props: (route) => {
          const customerId = route.query.customerId;
          const order = JSON.parse(route.query.order as string);
          return {
            customerId,
            order: new Order(order),
          };
        },
        meta: {
          title: 'Create Order',
          subtitle: `
            Fill out and submit an order form to create it in our system.
            Once created, you'll be notified, and your order will go through
            our approval process.
          `,
          breadcrumbs: [{
            text: 'Create',
            disabled: true,
          }] as RouterBreadcrumb[],
        },
      },
      {
        path: ':id',
        name: 'orders-view',
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Dashboard/Orders/OrdersView.vue'),
        props: (route: Route) => ({ id: route.params.id }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'View Order',
          subtitle: `
            Review this order's details, items, and comments.
          `,
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: this.$route.params.id,
              to: { params: { id: this.$route.params.id } },
            }];
          },
        },
      },
      {
        path: ':id/edit',
        name: 'orders-edit',
        meta: {
          title: 'Edit Order',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: this.$route.params.id,
              href: `/dashboard/orders/${this.$route.params.id}`,
            },
            {
              text: 'Edit',
            }];
          },
        },
        component: () => import(/* webpackChunkName: "Orders" */ '@/views/Dashboard/Orders/OrdersEdit.vue'),
        props: (route) => ({ id: route.params.id }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
      },
    ],
  },
];

export default config;
