import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { RouteConfig } from 'vue-router/types/router';

const settings: Array<RouteConfig> = [
  {
    path: 'settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/Dashboard/Settings.vue'),
    meta: {
      requiresAuth: true,
      title: 'Dashboard Settings',
      subtitle: 'Adjust settings for the dashboard\'s interface, manage cached files, view application information.',
      breadcrumbs: [{
        text: 'Settings',
      }] as RouterBreadcrumb[],

    },
  },
];

export default settings;
