













































































































import { ContextBarAction } from './types/ContextBarAction.type';
import { Vue, Component, Ref } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';
import { ContextBarManager } from './classes/ContextBarManager';
import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import Logger from '@/tools/Logger';

@Component({
  name: 'ContextBar',
})
export class ContextBar extends Vue {
  @Get('context/theme@dark')
  protected isDarkTheme!: boolean;

  @Sync('ui/contextBar@actions')
  protected contextBarActions!: ContextBarAction[];

  @Sync('ui/global@loading')
  protected uiLoading!: boolean;

  @Sync('layout/contextBarHeight')
  protected contextBarHeight!: number;

  @Ref('context-bar')
  private readonly contextBarRef!: Vue;

  protected selectedItem: any = null;

  protected loading = false;

  private readonly logger = new Logger({ context: 'UsersCreateDialog' });

  /**
   * Get a list of breadcrumbs specified in the router
   */
  protected get breadcrumbs(): RouterBreadcrumb[] {
    const breadcrumbs = this.$route.matched.reduce<RouterBreadcrumb[]>((all, route) => {
      let bcs: RouterBreadcrumb[] = [];

      if (typeof route.meta?.breadcrumbs === 'function') {
        bcs = route.meta.breadcrumbs.apply(this);
      } else if (Array.isArray(route.meta?.breadcrumbs)) {
        bcs = route.meta.breadcrumbs;
      }

      bcs.forEach((bc) => {
        bc.disabled = false;
        all.push(bc);
      });

      return all;
    }, []);

    if (breadcrumbs && breadcrumbs.length) {
      breadcrumbs[breadcrumbs.length - 1].disabled = true;
    }

    return breadcrumbs;
  }

  private get maxActions(): number {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 0;
      case 'sm':
        return 2;
      case 'md':
      case 'lg':
      default:
        return 4;
    }
  }

  protected get actions() {
    return this.contextBarActions.slice(0, this.maxActions);
  }

  protected get overflow() {
    return this.contextBarActions.slice(this.maxActions);
  }

  public mounted() {
    window.addEventListener('resize', this.onResize);
    this.$nextTick(this.onResize);
  }

  public destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  /**
   * Click handler for ContextBar actions
   */
  protected async onClick(action: ContextBarAction) {
    try {
      ContextBarManager.validateAction(action);
    } catch (error) {
      throw Error(`ContextBar: ${error.message}`);
    }

    if (action.callback) {
      this.uiLoading = true;
      try {
        await action.callback();
      } catch (error) {
        this.logger.error(error);
      }
      this.uiLoading = false;
    }
    if (action.to) this.$router.push(action.to);
  }

  private onResize() {
    this.contextBarHeight = this.contextBarRef.$el.getBoundingClientRect().height;
  }
}

export default ContextBar;
