import { ModelService } from './ModelService';
import { prepareData, Product } from '@/models/internal';
import { IFindArguments, IFindOneArguments, IUpdateArguments } from '@/lib/interfaces';
import { AssetService } from '@/services/AssetService';

/**
 * Service class for interacting with the Product model
 */
export class ProductService extends ModelService<typeof Product> {
  /**
   * Cached instance of the service
   */
  private static instance: ProductService | null = null;

  protected model = Product;

  protected path = '/products';

  /**
   * Constructor
   */
  private constructor() {
    super();
  }

  /**
   * Get an instance of the CustomerService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new ProductService();
      return this.instance;
    }
    return this.instance;
  }

  public static mapData(data: any) {
    const sourceReferences = (data.source_references) ? data.source_references : {};

    return {
      assets: data.assets ? prepareData(data.assets, AssetService.mapData) : [],
      collections: data.collections ? data.collections : [],
      billing_category: data.billing_category,
      color_description: data.color_description,
      country_of_origin: data.country_of_origin,
      created_at: data.created_at,
      description: data.description,
      fabric_composition: data.fabric_composition,
      gender: data.gender,
      ht_code: data.ht_code,
      id: data.id,
      image_url_small: data.image_url_small,
      image_url: data.image_url,
      inventory: data.inventory,
      is_apparel: data.is_apparel,
      manufacture_method: data.manufacture_method,
      meta: data.meta,
      price: data.price,
      season: data.season,
      size_scale_code: data.size_scale_code,
      sku: data.sku,
      source_references: sourceReferences,
      title: data.title,
      unit_type: data.unit_type,
      upc: data.upc,
      updated_at: data.updated_at,
    };
  }

  public get api() {
    return {
      /**
       * Create a Product on the server via POST request
       */
      create: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Find one Product on the server via GET request
       */
      findOne: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Find a list of Products on the server via GET request
       */
      find: async (args: IProductFindArguments): Promise<IProductFindResponse> => {
        const { data } = await this.apiService.get(this.path, args);
        return data;
      },

      /**
       * Update an existing Product on the server via PUT request
       */
      update: async (args: IProductUpdateArguments) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Delete an existing Product on the server via DELETE request
       */
      destroy: async () => {
        throw Error('This method has no implementation yet');
      },

      /**
       * Sync latest Products data to dashboard
       */
      sync: async (args: any) => {
        return await this.apiService.post(`${this.path}/sync`, args);
      },
    };
  }
}

interface IProductFindArguments extends IFindArguments {
  page?: number | string;
  per_page?: number | string;
  title_containing?: string;
  sku_containing?: string;
}

interface IProductFindResponse {
  page?: number;
  per_page?: number;
  total: number;
  products: any[];
}

interface IProductUpdateArguments extends IUpdateArguments {
  product: {
    meta: Record<string, any>;
  };
}
