import { Asset } from '@/models/internal';
import { ModelService } from '@/services/ModelService';
import {
  ICreateArguments,
  IFindOneArguments,
  IFindArguments,
  IApiService,
  IRequestArguments,
} from '@/lib/interfaces';

/**
 * Service class for interacting with the Asset model
 */
export class AssetService extends ModelService<typeof Asset> {
  /**
   * Cached instance of the service
   */
  private static instance: AssetService | null = null;

  protected model = Asset;

  private constructor() {
    super();
  }

  /**
   * Get an instance of the CustomerService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new AssetService();
      return this.instance;
    }
    return this.instance;
  }

  public static mapData(data: any) {
    data.assetable_type = AssetService.mapAssetableType(data.assetable_type);
    return {
      id: data.id,
      type: data.type.toLowerCase(),
      assetable_id: data.assetable_id,
      assetable_type: data.assetable_type,
      created_at: data.created_at,
      updated_at: data.updated_at,
      meta: data.meta,
      url: data.url,
      versions: data.versions,
    };
  }

  /**
   * Return a client-side defined type for an "assetable" from the server.
   *
   * We do this is because our server's concept of an "assetable type" is different
   * than ours. For example, the API may define an `Order` as a "DraftOrder". This would
   * mean our `assetable_type` field would be defined as `DraftOrder` rather than `orders`
   * to match our own specification for assetable types.
   *
   * @param type Assetable type from server
   * @example
   *  const assetableType = AssetService.mapAssetableType('DraftOrder'); // 'orders'
   */
  private static mapAssetableType(type: string): string {
    switch (true) {
      case /^product/i.test(type):
        return 'products';
      default:
        throw Error('Unable to determine assetable type - ensure assetable is supported');
    }
  }

  public get api(): IAssetService {
    return {
      /**
       * Create a Customer on the server via POST request
       */
      create: async (args) => {
        const path = this.path(args);
        const formData = new FormData();

        formData.append('upload', args.upload);
        formData.append('authentication_token', args.authentication_token);

        const { data } = await this.apiService.post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        return data;
      },

      /**
       * Find one Customer on the server via GET request
       */
      findOne: async (args) => {
        const path = this.path(args);
        const { data } = await this.apiService.get(`${path}/${args.id}`, args);
        return data;
      },

      /**
       * Find a list of Customers on the server via GET request
       */
      find: async (args) => {
        const path = this.path(args);
        const { data } = await this.apiService.get(path, args);
        return data;
      },
    };
  }

  protected path = (args: IAssetPathArguments) => `/${args.entity}/${args.entity_id}/assets`;
}

/**
 * Args for building a specific URL to make requests to server.
 * Provide an `entity` string which acts as the resource name
 * the assets belong to (ex. "products"). Also, provide
 * the `entity_id` of the `entity` to target a specific record.
 */
interface IAssetPathArguments extends IRequestArguments {
  /**
   * Parent entity name used in creating endpoints to make
   * requests to
   */
  entity: string;
  /**
   * Parent entity ID
   */
  entity_id: string | number;
}

interface IAssetUploadArguments extends IRequestArguments {
  /**
   * Object containing asset data
   */
  upload: any;
  /**
   * (Optional) Metafields
   */
  meta?: {
    [field: string]: string | number;
  };
}

interface IAssetFindArguments {
  /**
   * Type of Assets we're looking for (ex. image, document, etc.)
   */
  type: string;
}

/**
 * Extension of API service for assetable ModelServices
 */
interface IAssetService extends Pick<IApiService, 'create' | 'findOne' | 'find'> {
  /**
   * Create a record on the server
   */
  create(args: ICreateArguments & IAssetPathArguments & IAssetUploadArguments): Promise<any>;

  /**
   * Find a single record from the server
   */
  findOne(args: IFindOneArguments & IAssetPathArguments): Promise<any>;

  /**
   * Find a list of records from the server
   */
  find(args: IFindArguments & IAssetFindArguments & IAssetPathArguments): Promise<any>;
}
