import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
import { Config } from '@/lib/types';

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

/**
 * Use configuration object to determine Vuetify theme
 */
export default (config: Config) => new Vuetify({
  theme: {
    options: { customProperties: true },
    ...config.theme,
  },
});
