import { make } from 'vuex-pathify';

export interface IContextState {
  endprogram: {
    showNotice: boolean;
    lastNotice: string | null;
  };

  /**
   * Data pertaining to the client's AdBlocker
   */
  adBlock: {
    /**
     * Client has an AdBlocker enabled
     */
    hasAdBlock: boolean;
    /**
     * Client hasn't opted out of the warning displayed in regards
     * to their AdBlocker, and it's OK to show it again
     */
    showWarning: boolean;
    /**
     * Date-string of the last time the user dismissed a warning about
     * their AdBlocker
     */
    lastWarning: string | null;
  };

  /**
   * UI theme options and values
   */
  theme: {
    /**
     * Theme is currently set to dark
     */
    dark: boolean;
  };

  /**
   * Overlay and page-loading indicator
   */
  overlay: {
    /**
     * Overlay is visible
     */
    visible: boolean;
  };
}

const state: IContextState = {
  endprogram: {
    showNotice: true,
    lastNotice: null,
  },
  adBlock: {
    hasAdBlock: false,
    showWarning: true,
    lastWarning: null,
  },
  theme: {
    dark: false,
  },
  overlay: {
    visible: false,
  },
};

const mutations = make.mutations(state);
const getters = make.getters(state);
const actions = make.actions(state);

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
