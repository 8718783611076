import { make } from 'vuex-pathify';
import { ContextBarAction } from '@/components/ContextBar/types/ContextBarAction.type';

export interface IUiState {
  global: {
    loading: boolean;
  };
  contextBar: {
    actions: Array<ContextBarAction>;
    hide: boolean;
  };
}

const state: IUiState = {
  global: {
    loading: false,
  },
  contextBar: {
    actions: [],
    hide: false,
  },
};

const mutations = make.mutations(state);
const getters = make.getters(state);
const actions = make.actions(state);

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
