import { AttrField, OrmModel, PrimaryKey, HasManyByField, BelongsToField, HasManyField } from 'vuex-orm-decorators';
import { ApprovalStatus, OrderStatus } from '@/lib/enum';
import { BaseModel, OrderItem, Product, Comment, Customer, Fulfillment } from '@/models/internal';
import type { ShippingAddress, BillingAddress } from '@/lib/types/Address.type';
import { ICommentable } from '@/lib/interfaces/Commentable.interface';

/**
 * Default values for initializing the ShippingAddress
 * object in the instance
 */
const rootShippingAddress: ShippingAddress = {
  facility_name: '',
  city: '',
  delivery_address: '',
  postal: '',
  country: 'CAN',
  province: 'BC',
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  cost_center: '',
};

/**
 * Default values for initializing the BillingAddress
 * object in the instance
 */
const rootBillingAddress: BillingAddress = {
  facility_name: '',
  city: '',
  delivery_address: '',
  postal: '',
  country: 'CAN',
  province: 'BC',
  contact_name: '',
  contact_phone: '',
  contact_email: '',
};

/**
 * Default values for initializing the OrderDetails
 * object in the instance
 */
const rootOrderDetails: OrderDetail = {
  date: '',
  delivery_instructions: '',
  priority: 'standard',
};

/**
 * Default status' for the various sections of a
 * Order form
 */
const rootApprovals: OrderApprovals = {
  'info': ApprovalStatus.Pending,
  'part_a': ApprovalStatus.Pending,
  'part_b': ApprovalStatus.Pending,
  'part_c': ApprovalStatus.Pending,
};

@OrmModel('orders')
export class Order extends BaseModel implements ICommentable {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'Order';
  }

  /**
   * Unique identifier for the Order
   */
  @PrimaryKey()
  @AttrField()
  public id!: number;

  /**
   * Array of Comment id's
   */
  @AttrField([])
  public comment_ids!: number[];

  /**
   * Array of Fulfillment id's
   */
  @AttrField([])
  public fulfillment_ids!: number[];

  /**
   * Customer id associated to the order
   */
  @AttrField(null)
  public customer_id!: number;

  /**
   * Email of the submitter
   */
  @AttrField(null)
  public submitters_email?: string;

  /**
   * An array of JSON objects detailing the previous states
   * of the Order form
   */
  @AttrField([])
  public previous_form_states!: Array<any>;

  /**
   * A notes field showing the most
   */
  @AttrField('')
  public notes!: string;

  /**
   * The Order's status according to the back end
   */
  @AttrField(null)
  public status!: OrderStatus;

  /**
   * Approvals object indicating which portion of the Order
   * has been approved/denied
   */
  @AttrField(rootApprovals)
  public approvals!: OrderApprovals;

  @AttrField(rootShippingAddress)
  public ship_address!: ShippingAddress;

  @AttrField(rootBillingAddress)
  public bill_address!: BillingAddress;

  @AttrField(rootOrderDetails)
  public order_detail!: OrderDetail;

  @AttrField(null)
  public created_at!: string;

  @AttrField(null)
  public updated_at!: string;

  @AttrField([])
  public section_counts!: SectionCount[];

  @HasManyField(OrderItem, 'order_id')
  public order_items!: OrderItem[];

  @HasManyByField(Fulfillment, 'fulfillment_ids')
  public fulfillments!: Fulfillment[];

  @HasManyByField(Comment, 'comment_ids')
  public comments!: Comment[];

  @BelongsToField(Customer, 'customer_id')
  public customer?: Customer;

  /**
   * Add OrderItem to Order of specific quantity
   * @param product Product
   * @param quantity Quantity of Product
   */
  public addItem(product: Product | Record<string, any>, quantity: number) {
    const cached = Product.find(product.id);

    if (!cached) {
      Product.insert({ data: product });
    } else {
      Product.update({ data: product });
    }

    const orderItem = new OrderItem({
      order_id: this.id ?? 0,
      product_id: product.id,
      quantity: quantity ?? 0,
    });

    this.order_items.push(orderItem);

    return orderItem;
  }

  /**
   * Check if Order has items in a particular section
   * @param section Section of Order form
   */
  public hasItemsIn(section: string): boolean {
    const sc = this.section_counts.find((sc) => sc.name === section);
    const section_total = sc?.total_units ?? 0;
    return section_total > 0;
  }
}

/**
 * Order Details type
 */
export type OrderDetail = {
  date: string;
  priority: 'standard' | 'rush' | 'emergency';
  delivery_instructions: string;
};

/**
 * Mapping of various approval status' for sections of
 * a Order form
 */
export type OrderApprovals = {
  [section: string]: ApprovalStatus;
};

/**
 * Total item quantity for a given section
 */
export type SectionCount = {
  name: string;
  total_units: number;
};

export default Order;
