


















































import { Vue, Component } from 'vue-property-decorator';
import { Sync } from 'vuex-pathify';
import { UserService } from '@/services';
import { Utility } from '@/tools/Utility';
import moment from 'moment';

// 7 days
const NOTICE_TIMEOUT_MS = 604800000;

@Component({
  name: 'EndProgramBanner',
})
export class EndProgramBanner extends Vue {
  @Sync('context/endprogram@showNotice')
  protected showNotice!: boolean;

  @Sync('context/endprogram@lastNotice')
  protected lastNotice!: string | null;

  protected dismissed = false;

  protected rememberChoice = false;

  protected timeoutExpired = true;

  protected loading = true;

  private readonly userService: UserService = UserService.getInstance();

  /**
   * Conditions for rendering our EndProgramBanner component
   */
  protected get show() {
    // return true;
    let result = !this.dismissed
      && !this.loading
      && this.timeoutExpired
      && this.showNotice
      && this.loggedInUser;
    return result;
  }

  protected get loggedInUser() {
    return this.userService.getActive();
  }

  protected get loggedInUserFirstName() {
    return Utility.titleCase(this.loggedInUser?.name.split(' ')[0] ?? '');
  }

  public created() {
    this.check();
  }

  /**
   * Dismiss button on warning banner clicked
   */
  protected dismiss() {
    this.dismissed = true;
    if (this.rememberChoice) this.showNotice = false;
    this.lastNotice = moment.utc().toISOString();
  }

  private async check() {
    this.loading = true;

    // Check our cooldown for showing the banner if the user hasn't
    // opted out of the warning permanently
    if (this.lastNotice) {
      const lastNoticeDate = moment.utc(this.lastNotice);
      const currentDate = moment.utc();
      const timeSince = currentDate.diff(lastNoticeDate);
      if (timeSince > NOTICE_TIMEOUT_MS) {
        this.timeoutExpired = true;
        this.lastNotice = null;
      } else {
        this.timeoutExpired = false;
      }
    }

    this.loading = false;
  }
}

export default EndProgramBanner;
