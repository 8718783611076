import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { AuthService } from '@/services/AuthService';
import { RouteConfig } from 'vue-router/types/router';

const authService = AuthService.getInstance();

const config: Array<RouteConfig> = [
  {
    path: 'reports',
    component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/Reports.vue'),
    beforeEnter: (to, from, next) => {
      if (authService.getAbility().can('read', 'Report')) {
        return next();
      }
      return next({ name: 'home' });
    },
    meta: {
      requiresAuth: true,
      breadcrumbs(): RouterBreadcrumb[] {
        return [
          {
            text: 'Reports',
            to: {
              name: 'home',
            },
          },
        ];
      },
    },
    children: [
      {
        path: 'phsaordercalculation',
        name: 'phsa-order-calculation',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/PHSAOrderCalculation.vue'),
        meta: {
          title: 'Phsa Order Calculation',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'Phsa Order Calculation',
                disabled: true,
              },
            ];
          },
        },
      },
      {
        path: 'eocppesupply',
        name: 'eoc-ppe-supply',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/EOCPPESupply.vue'),
        meta: {
          title: 'EOC PPE Supply',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'EOC PPE Supply',
                disabled: true,
              },
            ];
          },
        },
      },
      {
        path: 'ppesupplybycategory',
        name: 'ppe-supply-by-category',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/PPESupplyByCategory.vue'),
        meta: {
          title: 'PPE Supply: Category',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'PPE Supply: Category',
                disabled: true,
              },
            ];
          },
        },
      },
      {
        path: 'ppesupplybysku',
        name: 'ppe-supply-by-sku',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/PPESupplyBySku.vue'),
        meta: {
          title: 'PPE Supply: Sku',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'PPE Supply: Sku',
                disabled: true,
              },
            ];
          },
        },
      },
      {
        path: 'ordersummary',
        name: 'order-summary',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/OrderSummary.vue'),
        meta: {
          title: 'Order Summary',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'Order Summary',
                disabled: true,
              },
            ];
          },
        },
      },
      {
        path: 'ordersummary/details',
        name: 'dist-channel-order-summary',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/DistChannelOrderSummary.vue'),
        meta: {
          title: 'Order Summary: Distribution Channel',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'Order Summary: Distribution Channel',
                disabled: true,
              },
            ];
          },
        },
        props: (route) => ({ distChannel: route.query.distChannel }),
      },
      {
        path: 'reoc/order',
        name: 'reoc-order-report',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/REOCOrderReport.vue'),
        meta: {
          title: 'REOC Order Report',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'REOC Order Report',
                disabled: true,
              },
            ];
          },
        },
      },
      {
        path: 'reoc/ppesupply',
        name: 'reoc-ppe-supply-report',
        component: () => import(/* webpackChunkName: "Reports" */ '@/views/Dashboard/Reports/REOCPPESupplyReport.vue'),
        meta: {
          title: 'REOC PPE Supply Report',
          breadcrumbs(): RouterBreadcrumb[] {
            return [
              {
                text: 'REOC PPE Supply Report',
                disabled: true,
              },
            ];
          },
        },
      },
    ],
  },
];

export default config;
