import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: '/reports/projected-inventory',
    name: 'projected-inventory',
    component: () => import(/* webpackChunkName: "Reports" */ '@/views/Reports/ProjectedInventory/ProjectedInventory.vue'),
    meta: {
      feature: 'blastrampReports',
      requiresAuth: false,
      layout: 'full-screen',
      title: 'Dated Inventory Report',
      subtitle: 'Discover future inventory levels for particular SKUs associated with pending purchase orders.',
    },
  },
];

export default config;
