import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: '/public/docs',
    component: () => import(/* webpackChunkName: "Docs" */ '@/views/Dashboard/Docs/Docs.vue'),
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: ':doc',
        name: 'public-docs-md',
        component: () => import(/* webpackChunkName: "Docs" */ '@/views/Dashboard/Docs/Markdown.vue'),
        meta: {
          layout: 'full-screen',
        },
        props: (route) => {
          const p = { popped: '', doc: route.params.doc };
          if (route.query.popped) p.popped = 'yes';
          return p;
        },
      },
    ],
  },
];

export default config;
