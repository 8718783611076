import { Collection } from '@/models/Collection';
import { ModelService } from '@/services/ModelService';
import { ICreateArguments, IDestroyArguments, IFindArguments, IFindOneArguments, IUpdateArguments } from '@/lib/interfaces';
import { UserService } from '.';
/**
 * Service class for interacting with the Collection model
 */
export class CollectionService extends ModelService<typeof Collection> {
  /**
     * Cached instance of the service
     */
  private static instance: CollectionService | null = null;

  protected model = Collection;

  protected path = '/collections';

  private readonly userService: UserService = UserService.getInstance();

  private constructor() {
    super();
  }

  /**
   * Get an instance of the CollectionService
   */
  public static getInstance() {
    if (!this.instance) {
      this.instance = new CollectionService();
      return this.instance;
    }
    return this.instance;
  }

  /**
   * Batch enable Customers for Collections
   */
  public async batchEnableCustomers(
    customer_ids: number[],
    collection_ids: number[],
    options?: ICollectionCustomerBatchArgumentOptions,
  ) {
    const authentication_token = this.userService.getActiveToken();
    const params: ICollectionCustomerBatchArguments = {
      authentication_token,
      collection_ids,
    };
    if (options?.select_all) {
      params.options = options;
    } else {
      params.customer_ids = customer_ids;
    }
    return this.api.batchEnableCustomers(params);
  }

  /**
   * Batch disable Customers for Collections
   */
  public async batchDisableCustomers(
    customer_ids: number[],
    collection_ids: number[],
    options?: ICollectionCustomerBatchArgumentOptions,
  ) {
    const authentication_token = this.userService.getActiveToken();
    const params: ICollectionCustomerBatchArguments = {
      authentication_token,
      collection_ids,
    };
    if (options?.select_all) {
      params.options = options;
    } else {
      params.customer_ids = customer_ids;
    }
    return this.api.batchDisableCustomers(params);
  }

  /**
   * Batch add Products to Collections
   */
  public async batchAddProducts(
    product_ids: number[],
    collection_ids: number[],
    options?: ICollectionProductBatchArgumentOptions,
  ) {
    const authentication_token = this.userService.getActiveToken();
    const params: ICollectionProductBatchArguments = {
      authentication_token,
      collection_ids,
    };
    if (options?.select_all) {
      params.options = options;
    } else {
      params.product_ids = product_ids;
    }
    return this.api.batchAddProduct(params);
  }

  /**
   * Batch remove Products from Collections
   */
  public async batchRemoveProducts(
    product_ids: number[],
    collection_ids: number[],
    options?: ICollectionProductBatchArgumentOptions,
  ) {
    const authentication_token = this.userService.getActiveToken();
    const params: ICollectionProductBatchArguments = {
      authentication_token,
      collection_ids,
    };
    if (options?.select_all) {
      params.options = options;
    } else {
      params.product_ids = product_ids;
    }
    return this.api.batchRemoveProduct(params);
  }

  public get api() {
    return {
      /**
       * Create a Collection on the server via POST request
       */
      create: async (args: ICollectionCreateArguments) => {
        const { data } = await this.apiService.post(this.path, args);
        return data;
      },

      /**
       * Create a Collection on the server via POST request
       */
      createBlacklist: async (args: ICollectionCreateBlacklistArguments) => {
        const { data } = await this.apiService.post(`${this.path}/${args.id}/blacklist`, args);
        return data;
      },

      /**
       * Find one Collection on the server via GET request
       */
      findOne: async (args: IFindOneArguments) => {
        const { data } = await this.apiService.get(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Find a list of Customers on the server via GET request
       */
      find: async (args: ICollectionFindArguments): Promise<ICollectionFindDto> => {
        const { data } = await this.apiService.get(this.path, args);
        return data;
      },

      /**
       * Update an existing Collection on the server via PATCH request
       */
      update: async (args: ICollectionUpdateArguments) => {
        const { data } = await this.apiService.patch(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Delete an existing Collection on the server via DELETE request
       */
      destroy: async (args: IDestroyArguments) => {
        const { data } = await this.apiService.delete(`${this.path}/${args.id}`, args);
        return data;
      },

      /**
       * Fetch a list of Customers associated with a Collection
       */
      findCustomers: async (args: ICollectionFindArguments) => {
        const path = `${this.path}/${args.id}/customers`;
        const { data } = await this.apiService.get(path, args);
        return data;
      },

      /**
       * Fetch a list of Products associated with a Collection
       */
      findProducts: async (args: ICollectionFindArguments) => {
        const path = `${this.path}/${args.id}/products`;
        const { data } = await this.apiService.get(path, args);
        return data;
      },

      /**
       * Enable a Customer for a specific Collection
       */
      enableCustomer: async (args: ICollectionCustomerArguments) => {
        const path = `${this.path}/${args.id}/customers/${args.customer_id}`;
        const { data } = await this.apiService.post(path, args);
        return data;
      },

      /**
       * Batch enable Customers for Collections
       */
      batchEnableCustomers: async (args: ICollectionCustomerBatchArguments) => {
        const path = `${this.path}/customers`;
        const { data } = await this.apiService.post(path, args);
        return data;
      },

      /**
       * Disable a Customer for a specific Collection
       */
      disableCustomer: async (args: ICollectionCustomerArguments) => {
        const path = `${this.path}/${args.id}/customers/${args.customer_id}`;
        const { data } = await this.apiService.delete(path, args);
        return data;
      },

      /**
       *  Batch disable Customers for Collections
       */
      batchDisableCustomers: async (args: ICollectionCustomerBatchArguments) => {
        const path = `${this.path}/customers`;
        const { data } = await this.apiService.delete(path, args);
        return data;
      },

      /**
       * Enable a Product for a specific Collection
       */
      addProduct: async (args: ICollectionProductArguments) => {
        const path = `${this.path}/${args.id}/products/${args.product_id}`;
        const { data } = await this.apiService.post(path, args);
        return data;
      },

      /**
       * Batch enable Products for Collections
       */
      batchAddProduct: async (args: ICollectionProductBatchArguments) => {
        const path = `${this.path}/products`;
        const { data } = await this.apiService.post(path, args);
        return data;
      },

      /**
       * Disable a Product for a specific Collection
       */
      removeProduct: async (args: ICollectionProductArguments) => {
        const path = `${this.path}/${args.id}/products/${args.product_id}`;
        const { data } = await this.apiService.delete(path, args);
        return data;
      },

      /**
       * Batch remove Products for Collections
       */
      batchRemoveProduct: async (args: ICollectionProductBatchArguments) => {
        const path = `${this.path}/products`;
        const { data } = await this.apiService.delete(path, args);
        return data;
      },
    };
  }
}

interface ICollectionCreateArguments extends ICreateArguments {
  collection: {
    name: string;
    code?: string;
    meta?: Record<string, any>;
  };
}

interface ICollectionCreateBlacklistArguments extends ICollectionCreateArguments {
  id: string | number;
}

interface ICollectionFindArguments extends IFindArguments {
  page?: number | string;
  per_page?: number | string;
  name_containing?: string; // Use for Collection
  sku_containing?: string; // Use for Product
  collection_ids?: Array<string|number>;
  show_blacklist_collections?: boolean; // Show blacklist collections at top level
}

interface ICollectionUpdateArguments extends IUpdateArguments {
  collection: {
    name: string;
  };
}

interface ICollectionCustomerArguments extends IFindOneArguments {
  customer_id: number | string;
}

export interface ICollectionCustomerBatchArguments extends ICreateArguments {
  collection_ids: number[] | string[];
  customer_ids?: number[] | string[];
  options?: ICollectionCustomerBatchArgumentOptions;
}

interface ICollectionProductArguments extends IFindOneArguments {
  product_id: number | string;
}

export interface ICollectionProductBatchArguments extends ICreateArguments {
  collection_ids: number[] | string[];
  product_ids?: number[] | string[];
  options?: ICollectionProductBatchArgumentOptions;
}

interface IFindDto {
  total: number;
  page?: number | string;
  per_page?: number | string;
}

interface ICollectionFindDto extends IFindDto {
  collections: any[];
}

interface ICollectionCustomerBatchArgumentOptions {
  select_all: boolean;
  name_containing?: string;
  dist_channels?: string[];
}

interface ICollectionProductBatchArgumentOptions {
  select_all: boolean;
  sku_containing: string;
}
