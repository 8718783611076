import { Logger } from '@/tools/Logger';
import { VueConstructor } from 'vue/types/umd';
import generators from './generators';

type MappedMethods = Record<string, (...args: any[]) => any>;
type Generator = keyof typeof generators;

export type GenifyPluginConfig = Record<Generator, Record<string, any>>;

/**
 * Vue plugin adding generator functions that are globally available
 */
export class GenifyPlugin {
  private static logger = new Logger({ context: 'Genify' });

  /**
   * Install function
   */
  public static install(Vue: VueConstructor, options: GenifyPluginConfig) {
    const mapGenify = function(this: any, prefix?: string) {
      const genify: MappedMethods = Object.freeze(generators);

      return Object.keys(generators).reduce((acc, name) => {
        const generatorName = name as Generator;
        const generatorOptions = options[generatorName];

        const methodName = (prefix || '') + generatorName;
        if (this[methodName]) {
          GenifyPlugin.logger.warn(`Vue prototype already has a property of "${methodName}". Skipping...`);
          return acc;
        }

        acc[methodName] = genify[generatorName](generatorOptions).bind(this);
        return acc;
      }, {} as MappedMethods);
    };

    // Define a mixin for the entire genify library
    Vue.mixin({
      beforeCreate() {
        Object.defineProperty(this, '$genify', {
          value: mapGenify.bind(this)(),
          writable: false,
          configurable: false,
          enumerable: false,
        });
      },
      // Dynamically create methods for Vue instances if they dont exist
      methods: mapGenify.bind(this)('$'),
    });
  }
}

export default GenifyPlugin;
