




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { UserService } from '@/services';
import User from '@/models/User';
import { Utility } from '@/tools/Utility';

const exampleItems = [
  {
    title: 'Settings',
    action: 'user-settings',
    color: 'primary',
  },
  {
    title: 'Logout',
    action: 'user-logout',
    color: 'red',
  },
];

@Component({
  name: 'UserIcon',
})
export default class UserIcon extends Vue {
  @Prop({ required: false, default: () => exampleItems })
  protected readonly items!: any[];

  private readonly userService: UserService = UserService.getInstance();

  private readonly user: User | null = this.userService.getActive();

  public created() {
    if (!this.user) throw Error('Unable to find an active User');
  }

  protected get roles() {
    const roles = this.user?.roles;
    if (!roles) throw Error('No roles available for this User');
    return (roles.length > 1)
      ? this.user?.roles.map((role) => Utility.titleCase(role)).join(', ')
      : Utility.titleCase(roles[0]);
  }

  /**
   * On click of menu option
   */
  protected onMenuOptionClick(option: string) {
    switch (option) {
      case 'user-settings': {
        const currentRoute = this.$route.name;
        if (currentRoute !== 'settings') {
          this.$router.push({ name: 'settings' });
        }
        break;
      }
      case 'user-logout': {
        this.$router.push({ name: 'logout' });
        break;
      }
      default: {
        break;
      }
    }
  }
}
