var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{ref:"context-bar",staticClass:"context-bar",attrs:{"height":_vm.$vuetify.breakpoint.name === 'xs' ? 48 : 56,"color":(_vm.isDarkTheme) ? undefined : 'white',"app":"","fixed":"","flat":"","outlined":"","elevation":"2"}},[_c('div',{staticClass:"d-flex justify-space-between",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-breadcrumbs',{staticClass:"context-bar__breadcrumbs",staticStyle:{"padding-left":"0"},attrs:{"items":_vm.breadcrumbs,"large":""}})],1),_c('div',{staticClass:"context-bar__actions-container d-flex flex-row align-center"},[(_vm.actions && _vm.actions.length)?_c('div',{staticClass:"context-bar__actions d-flex flex-row align-center"},_vm._l((_vm.actions),function(action){return _c('v-tooltip',{key:action.label,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","fab":"","text":"","color":action.color,"disabled":_vm.uiLoading},on:{"click":function($event){return _vm.onClick(action)}}},on),[_c('v-icon',[_vm._v(_vm._s(action.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(action.label))])])}),1):_vm._e(),(_vm.overflow && _vm.overflow.length)?_c('v-menu',{staticClass:"context-bar__actions-overflow",attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","fab":"","text":"","disabled":_vm.uiLoading}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('span',[_vm._v("More")])])]}}],null,false,918559908)},[_c('v-list',{attrs:{"flat":"","dense":""}},[_c('v-subheader',[_vm._v("ACTIONS")]),_c('v-list-item-group',{attrs:{"color":"primary"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.overflow),function(action){return _c('v-list-item',{key:action.label,on:{"click":function($event){return _vm.onClick(action)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":action.color},domProps:{"textContent":_vm._s(action.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(action.label)}})],1)],1)}),1)],1)],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }