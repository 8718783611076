


































import { Vue, Component } from 'vue-property-decorator';
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer.vue';
import AppBar from '@/components/AppBar/AppBar.vue';
import { Utility } from '@/tools/Utility';
import { ContextBar } from '@/components/ContextBar/ContextBar.vue';

@Component({
  name: 'MainLayout',
  components: {
    NavigationDrawer,
    AppBar,
    ContextBar,
  },
})
export class MainLayout extends Vue {
  protected drawer = false;

  protected applicationName = '';

  protected year = new Date().getFullYear();

  public mounted() {
    this.init();
  }

  private async init() {
    const config = await Utility.fetchConfig();
    this.applicationName = config.portal.name;
  }
}

export default MainLayout;
