import { BaseModelType } from '@/models/internal';
import { Create, Insert, InsertOrUpdate, UpdateObject } from '@vuex-orm/core/lib/modules/payloads/Actions';
import { InstanceOf, Query } from '@vuex-orm/core';
import { Lists, Item, List } from '@/lib/types';
import { ApiService } from './ApiService';

export abstract class ModelService<T extends BaseModelType> {
  /**
   * Service for making requests to the API
   */
  protected readonly apiService: ApiService = ApiService.getInstance();

  /**
 * Model's API wrapper
 */
  public abstract api: any;

  /**
   * Model that this service deals with
   */
  protected abstract readonly model: T;

  /**
   * Model-specific path appended to the API endpoint. Must start
   * with a "/".
   * @example
   *  As a string
   *  this.path === 'products'; // true
   * @example
   *  As a function
   *  this.path({ entity: 'products', entity_id: 123 }); // api/v1/products/123/assets
   */
  protected abstract readonly path: ((args: { entity: string; entity_id: number }) => string) | string | null;

  /**
   * Create model record(s) and return list of records created, including
   * through associations, and also clearing out existing records of this
   * type
   * @param payload Args
   */
  public create(payload: Create): Promise<Lists> {
    return this.model.create(payload);
  }

  /**
   * Update model record(s) and return list of records updated, including
   * through associations
   * @param payload Args
   */
  public update(payload: UpdateObject): Promise<Lists> {
    return this.model.update(payload);
  }

  /**
   * Insert model record(s) and return list of records inserted, including
   * through associations
   * @param payload Args
   */
  public insert(payload: Insert): Promise<Lists> {
    return this.model.insert(payload);
  }

  /**
   * Insert or update model record(s) and return list of records inserted and/or
   * updated, including through associations
   * @param payload Args
   */
  public insertOrUpdate(payload: InsertOrUpdate): Promise<Lists> {
    return this.model.insertOrUpdate(payload);
  }

  /**
   * Search ORM for single record
   * @param options
   */
  public find(options: string | number | (string | number)[]): Item<InstanceOf<T>> {
    return this.model.find(options);
  }

  /**
   * Search ORM for multiple records
   * @param options
   */
  public findIn(options: (string | number | (string | number)[])[]): List<InstanceOf<T>> {
    return this.model.findIn(options);
  }

  /**
   * Retrieve all records from ORM
   */
  public all(): List<InstanceOf<T>> {
    return this.model.all();
  }

  /**
   * Return a query builder for searching the ORM
   */
  public query(): Query<InstanceOf<T>> {
    return this.model.query();
  }
}

export default ModelService;
