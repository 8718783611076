import { AttrField, OrmModel, PrimaryKey } from 'vuex-orm-decorators';
import { FulfillmentStatus } from '@/lib/enum';
import { BaseModel } from '@/models/internal';

@OrmModel('fulfillments')
export class Fulfillment extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() { return 'Fulfillment'; }

  /**
   * Unique identifier for the Fulfillment (Order Marshal's Order ID).
   */
  @PrimaryKey()
  @AttrField()
  public id!: number;

  /**
   * Dashboard's Order ID
   */
  @AttrField()
  public order_id!: number;

  @AttrField(null)
  public status!: FulfillmentStatus;

  @AttrField({})
  public fulfillment_detail!: FulfillmentDetail;

  /**
   * Order ID appended with a code for which section the Order is from. We
   * use this code to parse out the `id` (DraftOrder ID from Order Marshal).
   *
   * Example: A fulfillment reference of "1234_PART_A" would be parsed into
   * an `id` of "1234".
   */
  @AttrField({})
  public fulfillment_reference!: string;

  @AttrField(null)
  public created_at!: string;

  @AttrField(null)
  public updated_at!: string;
}

/**
 * Fulfillment Details type
 */
export type FulfillmentDetail = {
  carrier?: any;
  created_at?: any;
  credit_note?: any;
  credit?: any;
  currency?: any;
  custom?: any;
  discount_note?: any;
  discount?: any;
  end_date?: any;
  external_customer_id?: any;
  freight_service?: any;
  freight_tax?: any;
  freight?: any;
  notes?: any;
  order_date?: any;
  order_id?: any;
  payments?: any;
  po?: any;
  priority?: any;
  rep_name?: any;
  season?: any;
  ship_date?: any;
  start_date?: any;
  tax?: any;
  terms?: any;
  tracking_number?: any;
  updated_at?: any;
};

export default Fulfillment;
