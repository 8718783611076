import '@/plugins/vue-class-component-hooks';
import Vue from 'vue';
import router from '@/router';
import genify, { GenifyPluginConfig } from '@/plugins/genify';
import createVuetify from '@/plugins/vuetify';
import App from '@/App.vue';
import { Utility } from '@/tools/Utility';
import { UserService } from '@/services/UserService';
import { SentryService } from './services/SentryService';
import { AuthService } from '@/services/AuthService';
import { Auth } from '@/models/internal';
import 'reflect-metadata';
import '@/registerServiceWorker';
import { Logger } from '@/tools/Logger';

const logger = new Logger({ context: 'Application' });

// Disable production tips from the Vue framework - they're annoying
Vue.config.productionTip = false;

// Install our Genify plugin to help with displaying various UI
// elements programmatically
Vue.use(genify, {
  alert: {
    el: 'genify__alerts',
  },
  notify: {
    el: 'genify__notifications',
  },
} as GenifyPluginConfig);

/**
 * Start the application asynchronously
 */
(async function() {
  try {
    const userService = UserService.getInstance();
    const authService = AuthService.getInstance();
    const sentryService = SentryService.getInstance();

    // Load dashboard's configuration and apply it where necassary
    const config = await Utility.fetchConfig();
    const release = await Utility.fetchVersion();
    const vuetify = createVuetify(config);

    // There's an issue with import synchronously: all the state gets
    // overwritten unless loaded asynchronously here
    const store = (await import('@/store')).default;

    const user = userService.getActive();
    const auth = (user?.auth) ? user.auth : new Auth();

    Vue.prototype.$ability = authService.defineAbility(auth);

    sentryService.initialize({ Vue, release });

    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  } catch (error) {
    logger.error(`Unable to start application: ${error.message}`);
  }
})();
