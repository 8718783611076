import { Asset, Collection, BaseModel } from '@/models/internal';
import { AttrField, MorphManyField, OrmModel, PrimaryKey } from 'vuex-orm-decorators';
import { AssetType } from './Asset';

@OrmModel('products')
export class Product extends BaseModel {
  /**
   * Model name (used for CASL subject detection)
   */
  public static get modelName() {
    return 'Product';
  }

  @PrimaryKey()
  @AttrField()
  public id!: number;

  @AttrField()
  public sku!: string;

  @AttrField()
  public season!: string;

  @AttrField({})
  public inventory!: Record<string, any>;

  @AttrField()
  public description!: string;

  @AttrField()
  public title!: string;

  @AttrField()
  public source_references!: Record<string, any>;

  @AttrField({})
  public meta!: Record<string, any>;

  @AttrField(null)
  public image_url?: string;

  @AttrField(null)
  public image_url_small?: string;

  @AttrField(null)
  public billing_category?: string;

  @AttrField(null)
  public color_description?: string;

  @AttrField(null)
  public country_of_origin?: string;

  @AttrField(null)
  public fabric_composition?: string;

  @AttrField(null)
  public gender?: string;

  @AttrField(null)
  public ht_code?: string;

  @AttrField(null)
  public is_apparel?: string;

  @AttrField(null)
  public manufacture_method?: string;

  @AttrField(null)
  public price?: string;

  @AttrField(null)
  public size_scale_code?: string;

  @AttrField(null)
  public unit_type?: string;

  @AttrField(null)
  public upc?: string;

  @AttrField(null)
  public created_at!: string;

  @AttrField(null)
  public updated_at!: string;

  @MorphManyField(Asset, 'assetable_id', 'assetable_type', 'id')
  public assets!: Asset[];

  @AttrField([])
  public collections!: Collection[];

  /**
   * Check if Product has inventory
   */
  public inStock() {
    return this.inventory.quantity > 0;
  }

  /**
   * Grab a Product's most recently uploaded asset
   */
  public latestAsset(): Asset | null {
    if (this.assets.length === 0) return null;
    return this.assets[this.assets.length - 1];
  }

  /**
   * Return the most recent Product image (as an Asset)
   */
  public latestImageAsset(): Asset | null {
    // Get the last record from the Product's list of Assets, or null
    const query: Asset | null = this.assets.sort((a, b) => a.id - b.id).reduce((asset: Asset, current: Asset) => {
      if (current.type.toLocaleLowerCase() === AssetType.Image) {
        asset = current;
      }
      return asset;
    }, (null as unknown) as Asset);

    if (query) return query;

    if (this.image_url_small || this.image_url) {
      return new Asset({
        url: String(this.image_url_small ?? this.image_url),
        type: AssetType.Image,
      });
    }

    return null;
  }
}

export default Product;
