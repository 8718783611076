import { RouterBreadcrumb } from '@/lib/types/RouterBreadcrumb.type';
import { RouteConfig } from 'vue-router/types/router';

const config: Array<RouteConfig> = [
  {
    path: 'customers',
    component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/Customers/Customers.vue'),
    meta: {
      requiresAuth: true,
      breadcrumbs: [{
        text: 'Sites',
        to: { name: 'customers-list' },
      }] as RouterBreadcrumb[],
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        name: 'customers-list',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/Customers/CustomersList.vue'),
        meta: {
          title: 'List Sites',
          subtitle: 'Browse through all the accessible site records that exist within our system.',
        },
      },
      // {
      //   path: 'create/',
      //   name: 'customers-create',
      //   component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/Customers/CustomersCreate.vue'),
      //   meta: {
      //     breadcrumbs: [
      //       {
      //         text: 'Create',
      //         disabled: true,
      //       },
      //     ] as RouterBreadcrumb[],
      //     title: 'Create a Site',
      //     subtitle: `
      //       Create a Site record and save it.
      //       <span class="font-weight-bold">All fields are required.</span>
      //     `,
      //   },
      // },
      {
        path: ':id',
        name: 'customers-view',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/Customers/CustomersView.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'View Site',
          subtitle: 'Review the details and data associated with this site\'s record.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: this.$route.params.id,
              to: { name: 'customers-view', params: { id: this.$route.params.id } },
            }];
          },
        },
      },
      {
        path: ':id/edit',
        name: 'customers-edit',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/Dashboard/Customers/CustomersEdit.vue'),
        props: (route) => ({
          id: route.params.id,
        }),
        beforeEnter: (to, _from, next) => {
          if (to.params.id) return next();
          throw Error('Missing `id` parameter');
        },
        meta: {
          title: 'Edit Site',
          subtitle: 'Modify the details and data associated with this site\'s record.',
          breadcrumbs(this: Vue): RouterBreadcrumb[] {
            return [{
              text: 'Edit',
              to: { name: 'customers-edit', params: { id: this.$route.params.id } },
            }];
          },
        },
      },
    ],
  },
];

export default config;
